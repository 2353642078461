// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import {fetchPODetailsFromPoId, fetchPOPerSku} from "../../../fnc.js"

const refConst = require("../../../constants.js").v

var initialState = {
	lastPoDetails: null,
	openedPO: null,
	poList: null,
}

function fnc(props) {

	const [lastPoDetails, setLastPoDetails] = useState(initialState.lastPoDetails);
	const [openedPO, setOpenedPO] = useState(initialState.openedPO);
	const [poList, setPoList] = useState(initialState.poList);

	useEffect(() => {

		props.setRefresher(Math.random())

	}, [openedPO, poList, lastPoDetails])

	useEffect(() => {
		fetchPOPerSku({
			"sku": props.item.sku
		}, (r) => {
			console.info('fetch_PO_per_sku:', r);
			setPoList(r.success && r.data && r.data.length > 0? r.data : [])
		})
		return () => {
			setPoList(initialState.poList)
		}
	}, [props.item])

	useEffect(() => {

		if(!openedPO){
			setLastPoDetails({});
		}else{
			fetchPODetailsFromPoId({
				"id": openedPO,
			}, null, (r) => {
				console.info('Fetched_lastPoDetails:', r);
				setLastPoDetails(r.success && r.data && r.data.length > 0 ? {
					"items": r.data,
					"po": r.poInfo
				} : initialState.lastPoDetails);
			});
		}
		return () => {
			setLastPoDetails(initialState.lastPoDetails)
		}
		
	}, [openedPO])

	return (props.sectionBuilder("Active PO", () => {

		let listData = [],
			ctnData = null;

		console.info("lastPoDetailslastPoDetails", lastPoDetails);
		if(openedPO){

			if(lastPoDetails && lastPoDetails.items){
				lastPoDetails.items.forEach((po, i) => {
					listData.push(<tr key={`po_${i}`} className={(props.item.sku == po.sku? "bg-primary text-white" : "") + " border-bottom"}>
						<td className="m-0 p-1 smallView">{po.index}</td>
						<td className="m-0 p-1 smallView">{po.upc}</td>
						<td className="m-0 p-1 smallView">{po.sku}</td>
						<td className="m-0 p-1 smallView">{po.qty}</td>
						<td className="m-0 p-1 smallView">{po.unit_price}</td>
						<td className="m-0 p-1 smallView">{po.status}</td>
					</tr>)
				})
				ctnData = <div key={`LastPODataSection`} className="table-responsive-sm" style={{maxHeight: "300px", overflowY: "auto"}}>
					<div>
						<button type="button" className="btn btn-secondary btn-sm mb-1 ml-1" onClick={() => setOpenedPO(null)}>Return</button>
					</div>
					<div className="smallView">
						{ lastPoDetails.po.po_id? <div>
							<span>ID: </span>
							{lastPoDetails.po.po_id}
						</div> : "" }
						{ lastPoDetails.po.order_date? <div>
							<span>Order Date: </span>
							{lastPoDetails.po.order_date}
						</div> : "" }
						{ lastPoDetails.po.status? <div>
							<span>Status: </span>
							{lastPoDetails.po.status}
						</div> : "" }
					</div> 
					<table className="">
						<thead>
							<tr>
								<th>ID</th>
								<th>UPC</th>
								<th>SKU</th>
								<th>QTY</th>
								<th>Price</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
							{listData}
						</tbody>
					</table>
				</div>
			}else if(lastPoDetails === initialState.lastPoDetails){
				ctnData = <div key={`LastPOLoading`} className="smallViewsadasdasd">Loading...</div>
			}else{
				ctnData = <div key={`LastPONoData`} className="smallView">No PO found</div>
			}
		}

		let ctnPoList = [];
		console.info('poListpoList', poList);
		if(poList && poList.length > 0){
			poList.forEach((po, i) => {
				ctnPoList.push(<div key={`order - ${po.order_id}`}>
					<a href="" onClick={(e) => {
						e.preventDefault();
						setOpenedPO(po.order_id)
					}}>{po.order_id} - {po.supplier} - {po.order_date}</a>
					<div>{po.childs.map((child) => {
						return <div key={`${po.order_id} - ${child.location}`} className="ml-2">{child.location}: {child.qty}</div>
					})}</div>
				</div>)
			})
		}else if(poList !== null){
			ctnPoList = <div>No Active PO</div>
		}else{
			ctnPoList = <div>Loading...</div>
		}

		return <div style={{
			maxHeight: "300px",
			overflowY: "auto"
		}}>
			{openedPO? ctnData : ctnPoList}
		</div>
	}, {
		minWidth: "280px",
	}));
}

export default fnc