// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import moment from 'moment';
import { capitalizeFLetter, ajaxPerso } from '../../fnc'


const refConst = require("../../constants.js").v

export default function EbayImgUpdater(props){

	const initialState = {
		// itemReadyForStoreSelected: {storeFrom: null, storeTo: null, items: []},
		storeFrom: null,
		masterVendorSelected: null,
		storeTo: null,
		itemsValid: [],
		items: [],
		categoriesStoreTo: {},
		brandsStoreTo: [],
		itemsCreated: {}, // {sku: {handle: '', id: ''}}
		submited: false
	}

	const [submited, setSubmited] = useState(initialState.submited);
	const [itemsCreated, setItemsCreated] = useState(Object.assign({}, initialState.itemsCreated));
	const [brandsStoreTo, setBrandsStoreTo] = useState(initialState.brandsStoreTo);
	const [storeFrom, setStoreFrom] = useState(initialState.storeFrom);
	const [storeTo, setStoreTo] = useState(initialState.storeTo);
	const [itemsValid, setItemsValid] = useState(initialState.itemsValid.slice(0));
	const [items, setItems] = useState(initialState.items.slice(0));
	const [categoriesStoreTo, setCategoriesStoreTo] = useState(initialState.categoriesStoreTo);
	const [version, setVersion] = useState("0.1.0");

	useEffect(() => {
		triggerModal(true);
	}, [items, props.itemsSelected])

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let triggerProcess = () => {

		let itemsSelected = props.itemsSelected
		if(itemsSelected.length === 0)
			return false

		getItemsData()

		triggerModal(false);
	}

	let triggerModal = (refresh) => {
		props.modal({
			show: refresh? false : true,
			title: <span>TMP IMG Corrector<h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			options: {
				width: "modal-xl"
			},
			html: (popup) => {
				return <div className="text-center">
					Comfirm by clicking on &quot;Start&quot;
					(This function will remove any script potentially present in the template and replace the product picture by the main profile picture on ebay)
				</div>
			}
			, exit: (popup, close) => {
				close()
			}
			, ok: !submited? {
				title: "Start",
				fnc: (popup, close) => {
					props.loading(true)

					console.info('items', items);

					if(items.length > 0){
						reqTriggerPage(items.map(o => {
							return o.id_item
						}), (r) => {
							console.info('Fetch Req correctProductImgInTmp', r);
							// setSubmited(false)
							props.loading(false)

							props.info({success: r.success, error: r.error})
						})
					}else{
						props.info({error: "No id_item found."})
					}
				}
			} : false, return: {
				title: "Return",
				fnc: (popup, close) => {
					resetPage()
					close()
				}
			}
		});
	}

	let fetchItems = (callbackItem) => {
		console.info('props.itemsSelected', props.itemsSelected);
		
		if(!props.itemsSelected)
			throw("Please make sure that you have selected some items.");
		else
			ajaxPerso( {
				"api": "ebay",
				"trigger": "getItems",
				"idProductListJson": JSON.stringify(props.itemsSelected),
			}, callbackItem);
	}

	let getItemsData = () => {
		fetchItems((r) => {
			console.info('Fetched Items', r);
			if(r.success){
				setItems(r.data)
			}else{
				props.info({"error": r.error})
			}
		})
	}

	let reqTriggerPage = (itemList, callbackPresence) => {
		if(!itemList)
			throw("Please indicate a storeId / itemList.");
		else
			ajaxPerso( {
				"api": "ebay",
				"trigger": "correctProductImgInTmp",
				"idItemList": itemList.join()
			}, callbackPresence);
	}

	return (
		<div className="list-group-item list-group-item-action p-2 text-center" onClick={() => triggerProcess()}>
			{
				props.title
			}
		</div>
	);
}