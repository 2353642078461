// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import Loading from './Loading.js';
import { ajaxPerso, loadMinSellingPrices, fetchAvgPerSku, fetchItemGlobalInfo, fetchPriceHistory, getSupplier, fetchBrands, fetchPODetailsFromPoId, fetchPOPerSku, fetchApiData, capitalizeFLetter } from '../../fnc.js'
import Select from 'react-select';
import moment from 'moment';
import QuantitiesAndPrices from './modalInfo/QuantitiesAndPrices.js';
import StoreLinks from './modalInfo/StoreLinks.js';
import Tags from './modalInfo/Tags.js';
import ActivePO from './modalInfo/ActivePO.js';
import SupplierInfo from './modalInfo/SupplierInfo.js';
import MinSellingPrices from './modalInfo/MinSellingPrices.js';
import LastFewSales from './modalInfo/LastFewSales.js';

const refConst = require("../../constants.js").v

export default function ModalItemInfo(props){

	const initialState = {
		submited: false,
		itemGlobalInfo: null,
		itemMargin: [],
		lastPoDetails: null,
		lastPO: null,

	}


	const [itemGlobalInfo, setItemGlobalInfo] = useState(initialState.itemGlobalInfo);
	const [itemMargin, setItemMargin] = useState(initialState.itemMargin);
	const [submited, setSubmited] = useState(initialState.submited);
	const [lastPO, setLastPO] = useState(initialState.lastPO);
	const [lastPoDetails, setLastPoDetails] = useState(initialState.lastPoDetails);
	const [version, setVersion] = useState("1.0.0");
	const [brands, setBrands] = useState(null);
	const [refresher, setRefresher] = useState(0);

	useEffect(() => {
		
		triggerModal(props.id_product, true);
		
	}, [itemMargin, itemGlobalInfo, submited, lastPO, lastPoDetails])

	/* useEffect(() => {
		//! * Just for development purpose. so the modal is always open.
		
		if(!props.modalOpen){
			if(!itemGlobalInfo)
				init()
			triggerModal(85239, false);
		}
		
	}, [props.modalOpen, itemGlobalInfo, submited, minPrices]) */

	const init = () => {

		resetForm();
		
		console.info("props.id_productprops.id_product", props.id_product);
		getItemGlobalInfo({"id_product": props.id_product});

		fetchBrands(refConst.id_store_finale, {}, () => {
			props.loading(true);
		}, (r) => {
			console.info('Fetched_brands:', r);
			props.loading(false);

			if(r.success){
				setBrands(r.data);
			}
		})
	}

	const resetForm = (r) => {
		for (const key in initialState) {
			if (Object.prototype.hasOwnProperty.call(initialState, key)) {
				const element = initialState[key];
				console.info('Eval result', eval("set" + capitalizeFLetter(key))( JSON.parse(JSON.stringify(element)) ));
			}
		}
	}
	
	const getItemGlobalInfo = (obj) => {
		// Reset the items and presence list.

		props.loading(true)

		fetchItemGlobalInfo({
			"id_product": obj.id_product
		}, (r) => {
			console.info('Fetched itemGlobalInfo:', r);

			setItemGlobalInfo(r)
	
			props.loading(false);
		}, (r) => {
			console.info('Fetched itemMargin:', r);
			
			setItemMargin(r)
		
			props.loading(false);
		});
	}

	const sectionBuilder = (title, fncCtnData, styles) => {

		return <div key={`blc_${title}`} style={styles? styles : {}} className={"m-2 mt-3 bg-light p-3 pt-4 d-inline-block border position-relative"}>
			<p className="mb-1 font-weight-bold pl-2 pr-2 position-absolute d-inline-block border bg-white" style={{
					top: "-12px",
			}}>{title}</p>
			{ fncCtnData() }
		</div>
	}

	let triggerModal = (id_product, refresh) => {

		props.modal({
			key: "Product Info",
			show: refresh? false : true,
			title: <span>Product Info: {itemGlobalInfo? itemGlobalInfo.sku : ""}<h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			options: {
				width: "modal-xl"
			},
			html: (popup) => {

				if(!itemGlobalInfo){
					return <Loading loading={true} centered={true} />
				}else{
					let o = itemGlobalInfo
					console.info("productData", o);
					let outputBlc = []

					outputBlc.push(<QuantitiesAndPrices key={`QuantitiesAndPrices`} item={o} refConst={refConst} sectionBuilder={sectionBuilder} setRefresher={setRefresher} />)

					outputBlc.push(<StoreLinks key={`StoreLinks`} item={o} refConst={refConst} sectionBuilder={sectionBuilder} setRefresher={setRefresher} />)
					
					outputBlc.push(<Tags key={`System Tags`} item={o} refConst={refConst} sectionBuilder={sectionBuilder} setRefresher={setRefresher} />)
					
					outputBlc.push(<LastFewSales key={`LastFewSales`} item={o} refConst={refConst} sectionBuilder={sectionBuilder} setRefresher={setRefresher} {...props} />)

					outputBlc.push(<SupplierInfo key={`SupplierInfo`} item={o} refConst={refConst} sectionBuilder={sectionBuilder} setRefresher={setRefresher} />)
					
					outputBlc.push(<ActivePO key={`activePO`} item={o} refConst={refConst} sectionBuilder={sectionBuilder} setRefresher={setRefresher} />)
					
					outputBlc.push(<MinSellingPrices key={`MinSellingPrices`} item={o} refConst={refConst} sectionBuilder={sectionBuilder} setRefresher={setRefresher} {...props} />)

					let brandName = null;
					if(brands){
						brandName = brands.find(b => {
							if(b.id === o.id_brand)	return b
						})
						if(brandName)
							brandName = brandName.name
					}

					return <div>
						<div>
							<div className="input-group mb-3">
								<div className="input-group-prepend">
									<span className="input-group-text">Title</span>
								</div>
								<input type="text" className="form-control bg-light" value={o.title ?? ""} readOnly/>
							</div>
							<div className="d-flex flex-row">
								<div className="input-group mb-3 w-50 mr-2">
									<div className="input-group-prepend">
										<span className="input-group-text">SKU</span>
									</div>
									<input type="text" className="form-control bg-light" value={o.sku ?? ""} readOnly/>
								</div>
								<div className="input-group mb-3 w-50 ml-2">
									<div className="input-group-prepend">
										<span className="input-group-text">UPC</span>
									</div>
									<input type="text" className="form-control bg-light" value={o.upc ?? ""} readOnly/>
								</div>
							</div>
							<div className="d-flex flex-row">
								<div className="input-group mb-3 w-50 mr-2">
									<div className="input-group-prepend">
										<span className="input-group-text">Location</span>
									</div>
									<input type="text" className="form-control bg-light" value={!o.qty_remaining? "No Stock" : o.location} readOnly/>
								</div>
								<div className="input-group mb-3 w-50 ml-2">
									<div className="input-group-prepend">
										<span className="input-group-text">Cost</span>
									</div>
									<input type="text" className="form-control bg-light" value={o.supplier1_price? "$" + o.supplier1_price : ""} readOnly/>
								</div>
								<div className="input-group mb-3 w-50 ml-2">
									<div className="input-group-prepend">
										<span className="input-group-text">MSRP</span>
									</div>
									<input type="text" className="form-control bg-light" value={o.msrp? "$" + o.msrp : ""} readOnly/>
								</div>
								<div className="input-group mb-3 w-50 ml-2">
									<div className="input-group-prepend">
										<span className="input-group-text">MAP</span>
									</div>
									<input type="text" className="form-control bg-light" value={o.map_price? "$" + o.map_price : ""} readOnly/>
								</div>
								<div className="input-group mb-3 w-50 ml-2">
									<div className="input-group-prepend">
										<span className="input-group-text">Lead Days</span>
									</div>
									<input type="text" className="form-control bg-light" value={o.supplier1_lead_days ?? ""} readOnly/>
								</div>
							</div>
							<div className="d-flex flex-row">
								{
									brandName? <div className="input-group mb-3 w-50 mr-2">
										<div className="input-group-prepend">
											<span className="input-group-text">Brand</span>
										</div>
										<input type="text" className="form-control bg-light" value={brandName ?? ""} readOnly/>
									</div> : ""
								}
								<div className="input-group mb-3 w-50 mr-2">
									<div className="input-group-prepend">
										<span className="input-group-text">Weight</span>
									</div>
									<input type="text" className="form-control bg-light" value={o.weight? o.weight + " " + o.weight_unit : ""} readOnly/>
								</div>
								<div className="input-group mb-3 w-50 mr-2">
									
								</div>
							</div>
						</div>
						<div className="d-flex flex-row align-items-start flex-wrap justify-content-center mt-2">
							{outputBlc}
						</div>
					</div>
				}
			}
			, exit: (popup, close) => {
				props.setStateSafe({
					"modalOpen": false,
				})
				close()
			},
			return: {
				title: !props.promoMetaObj? "Close" : "Remove Promo",
				fnc: (popup, close) => {
					props.setStateSafe({
						"modalOpen": false,
					})
					close()
				}
			}
		});
	}

	/**
	 * This paghe should return a callback for the onClick event.
	 */

	/* if(!props.modalOpen && props.setStateSafe){
		props.setStateSafe({
			"modalOpen": true,
		})
	} */

	return (
		<div className={props.className} onClick={(e) => {
			if(e){
				e.preventDefault();
				e.stopPropagation();
			}
			props.setStateSafe({
				"modalOpen": true,
			})
			init()
			triggerModal(props.id_product, false);
		}}>
			{props.title}
		</div>
	);
}