import React, { Component } from 'react';
import $ from 'jquery';
import logoStoreEio from '../../../img/fav-trans-eio-border.png';
import logoStoreHaus from '../../../img/fav-trans-hausOfTools.png';
import logoStoreEbay from '../../../img/ebay_circle_perso.png';
import logoStoreWalmart from '../../../img/walmart_circle_updated.png';
import logoStoreAmazon from '../../../img/amazon_circle_perso.png';
import ShowPresence from './../ShowPresence.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import ModalItemInfo from '../ModalItemInfo.js'
const refConst = require("../../../constants.js").v

class CatalogItems extends Component {

	constructor(props) {
		super(props);

		// this.logoStoreWantedList = [logoStoreEbay, logoStoreAmazon, logoStoreWalmart, logoStoreEio, logoStoreHaus];
		this._isMounted = false;

		this.state = {
			stores: props.stores,
			modalOpen: false
		};

		console.info('props.stores', props.stores);
		
		this.StoreWantedList = [];
		
		// Bind Allow access by "this" into the function
		this.setStateSafe = this.setStateSafe.bind(this);
	}
	
	/* componentWillMount() {
		this.initialState = this.state
	} */

	componentDidUpdate(prevProps){
		
		$('#contentBody').scrollspy({ target: '#navTopHistory' })

		$('[data-toggle]').tooltip('dispose').tooltip({
			placement: "bottom"
		}).tooltip('update')
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		// this.initialState = this.state
		this._isMounted = false;
	}

	setStateSafe() {

		if(this._isMounted)
			this.setState(...arguments, () => {
				// console.log('setStateSafe', this.state);
			})
	}

	addItemsToView() {
		let items = this.props.items,
			returnData = [],
			lengthTab = items.length

		/* console.log('items', items);
		console.log('presenceList', presenceList); */

		if(items && items.length > 0)
			for (let i = 0; i < lengthTab; i++) {
				const obj = items[i]
				/* let presence = presenceList[i]

				// Security if the order is different from the server.
				if(presence && obj && parseInt(presence.id) != parseInt(obj.id)){
					presence = presenceList.find(o => {
						if(o.id == obj.id)	return o
					})
				} */

				if(!obj)
					continue
				//  + "__" + (presence? presence.id : '')
				
				returnData.push(<tr className="pt-2 pb-2 catalogItemHover align-items-center" key={`list_${obj.id}_${obj.id_item_marketplace}_items`}>
					<td className="align-middle" style={{paddingLeft: "10px", paddingRight: "10px"}}>
						<label className="ctn_checkbox">
							<input type="checkbox" onChange={this.props.onSelectItems} checked={this.props.itemsSelected.indexOf(parseInt(obj.id)) >= 0? true : false} value={obj.id}/>
							<span className="checkmark"></span>
						</label>
					</td>
					<td style={{maxWidth: "100px", width: "100px"}} className="border-right align-middle text-center smallText">{obj.id}</td>
					<td style={{maxWidth: "40px", width: "40px"}} className={"border-right align-middle text-center " + (obj.is_active? "text-success" : "text-danger")}><FontAwesomeIcon icon={obj.is_active? faCheckCircle : faMinusCircle} title={obj.is_active? "Active" : "Inactive"}/></td>
					<td className="border-right align-middle text-center" style={{overflow: "auto", maxWidth: "140px", width: "140px"}} data-toggle="tooltip" title={obj.vendor}>{obj.vendor}</td>
					<td className="border-right align-middle text-center pointer" style={{overflow: "auto", maxWidth: "180px", width: "180px"}} data-toggle="tooltip" title={obj.sku}>
						<u><ModalItemInfo {...this.props} title={obj.sku} id_product={obj.id} {...this.state} setStateSafe={this.setStateSafe} /></u>
					</td>
					<td className="border-right align-middle " style={{overflow: "auto", maxWidth: "150px"}} data-toggle="tooltip" title={obj.title}>
						{obj.title}
					</td>
					<td className="border-right align-middle text-center" style={{minWidth: "90px", maxWidth: "90px", width: "90px"}}>{obj.cost? '$' + parseFloat(obj.cost).toFixed(2) : '-'}</td>
					<td className="border-right align-middle text-center" style={{minWidth: "90px", maxWidth: "90px", width: "90px"}}>{obj.msrp? '$' + parseFloat(obj.msrp).toFixed(2) : '-'}</td>
					<td className="border-right align-middle text-center" style={{minWidth: "90px", maxWidth: "90px", width: "90px"}}>{obj.map_price?'$' +  parseFloat(obj.map_price).toFixed(2) : '-'}</td>
					<td className="border-right align-middle text-center" style={{overflow: "hidden", maxWidth: "90px", width: "90px"}}>{obj.qty_on_hand || '-'}</td>
					<td className="border-right align-middle text-center smallText" style={{overflow: "hidden", maxWidth: "140px", width: "140px", minWidth: "140px"}}>{ obj.location }</td>
					<td className="align-middle text-center smallText" style={{overflow: "hidden", maxWidth: "140px", width: "140px", minWidth: "140px"}}>{ obj.date_created }</td>
					<td className="align-middle text-center smallText">
						{<ShowPresence stores={this.props.stores} list_id_store={obj.list_id_store} return={"info"}/>}
					</td>
					{
						/* this.props.idStoreWantedList.map(idStore => {
							let oS = this.state.stores.find( o => {
								if(o.id === idStore)	return o
							})
							if(oS)
								return <div key={oS.id+"_storeId"} className={(obj.list_id_store.indexOf(oS.id) !== -1? "bg-success" : "bg-warning") + " border-right p-0 col-sm align-middle text-center smallText"} style={{overflow: "hidden", minWidth: "35px", maxWidth: "35px"}} title={oS.name}></div>
						}) */
					}
					<td className="border-right align-middle text-center" style={{paddingLeft: "10px", paddingRight: "10px"}}>
						<a href={refConst.finaleLinkProduct + btoa('/eio/api/product/' + obj.sku)} target="_blank"  rel="noopener noreferrer">link</a>
						<button type="button" className="btn btn-primary btn-sm ml-3" onClick={() => this.props.openModal(obj.id)}>
							<ModalItemInfo
								{...this.props}
								title={"Details"}
								id_product={obj.id}
								{...this.state}
								setStateSafe={this.setStateSafe}
							/>
						</button>
					</td>
				</tr>)

			}
		return returnData
	}


	render() {
		/* return (
			<div className="middeSizeText ctnCatalogItems" style={{overflow: "auto"}}>
				<div id="navTopHistory" className="row sticky-top bg-white font-weight-bold pt-2 pb-2">
					<div className="col-sm" style={{maxWidth: "60px"}}>
						<label className="ctn_checkbox">
							<input type="checkbox" onChange={(e) => this.props.onSelectItems(e, true)} ref={this.props.mainCheckbox} />
							<span className="checkmark bg-dark"></span>
						</label>
					</div>
					<div className="col" style={{maxWidth: "100px", width: "100px", minWidth: "100px"}}>#</div>
					<div className="col p-0" style={{maxWidth: "40px", width: "40px"}}>Status</div>
					<div className="col" style={{maxWidth: "140px", width: "140px"}}>Brand</div>
					<div className="col" style={{maxWidth: "180px", width: "180px"}}>Sku</div>
					<div className="col-md">Title</div>
					<div className="col" style={{maxWidth: "90px", width: "90px", minWidth: "90px"}}>Cost</div>
					<div className="col" style={{maxWidth: "90px", width: "90px", minWidth: "90px"}}>Msrp</div>
					<div className="col" style={{maxWidth: "90px", width: "90px", minWidth: "90px"}}>Map</div>
					<div className="col" style={{maxWidth: "90px", width: "90px"}}>QoH </div>
					<div className="col" style={{maxWidth: "140px", width: "140px"}}>location</div>
					<div className="col" style={{maxWidth: "140px", width: "140px"}}>Date Created</div>

					{
						<ShowPresence stores={this.props.stores} return={"icons"} />
					}

					<div className="col">Url</div>
				</div>
				{ this.addItemsToView() }
			</div>
		); */

		return (
			<div className="w-100" style={{
				overflow: "hidden",
				overflowX: "auto",
				paddingBottom: "20px"
			}}>
				<table className="middeSizeText ctnCatalogItems" style={{overflow: "auto", minWidth: "100%"}}>
					<thead>
						<tr id="navTopHistory" className="bg-white font-weight-bold pt-2 pb-2">{/* sticky-top */}
							<td className="text-center" style={{paddingLeft: "10px", paddingRight: "10px"}}>
								<label className="ctn_checkbox">
									<input type="checkbox" onChange={(e) => this.props.onSelectItems(e, true)} ref={this.props.mainCheckbox} />
									<span className="checkmark bg-dark"></span>
								</label>
							</td>
							<td className="text-center" style={{maxWidth: "100px", width: "100px", minWidth: "100px"}}>#</td>
							<td className="text-center" style={{maxWidth: "40px", width: "40px"}}>Status</td>
							<td className="text-center" style={{maxWidth: "140px", width: "140px"}}>Brand</td>
							<td className="text-center" style={{maxWidth: "180px", width: "180px"}}>Sku</td>
							<td className="text-center">Title</td>
							<td className="text-center" style={{maxWidth: "90px", width: "90px", minWidth: "90px"}}>Cost</td>
							<td className="text-center" style={{maxWidth: "90px", width: "90px", minWidth: "90px"}}>Msrp</td>
							<td className="text-center" style={{maxWidth: "90px", width: "90px", minWidth: "90px"}}>Map</td>
							<td className="text-center" style={{maxWidth: "90px", width: "90px"}}>QoH </td>
							<td className="text-center" style={{maxWidth: "140px", width: "140px"}}>location</td>
							<td className="text-center" style={{maxWidth: "140px", width: "140px"}}>Date Created</td>
							<td className="text-center">
								{
									<ShowPresence stores={this.props.stores} return={"icons"} />
									/* this.logoStoreWantedList.map((img, index) => {
										return <div key={this.props.idStoreWantedList[index]+"_logoStore"} className="col p-0" style={{minWidth: "35px", maxWidth: "35px", width: "35px"}}><img src={img} className="" style={{height: "35px"}}></img></div>
									}) */
								}
							</td>
							<td className="text-center">Url</td>
						</tr>
					</thead>
					<tbody>
						{ this.addItemsToView() }
					</tbody>
				</table>
			</div>
		);
	}
}

export default CatalogItems;
