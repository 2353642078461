// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import {fetchPriceHistory} from "../../../fnc.js"
import logoStoreEio from '../../../img/fav-trans-eio-border.png';
import logoStoreHaus from '../../../img/fav-trans-hausOfTools.png';
import logoStoreEbay from '../../../img/ebay_circle_perso.png';
import logoStoreWalmart from '../../../img/walmart_circle_updated.png';
import logoStoreAmazon from '../../../img/amazon_circle_perso.png';
import logoStoreAmazonGott from '../../../img/amazon_circle_perso_gott_v2.png';

const refConst = require("../../../constants.js").v

var initialState = {
	priceHistoryPerStore: {},
}

let listStoreInfo = [
	{
		id: refConst.id_store_eio,
		logo: logoStoreEio,
		name: "EIO",
		qtyProp: "shopify_eio_qty",
		priceProp: "eio_price",
	},
	{
		id: refConst.id_store_hausfTools,
		logo: logoStoreHaus,
		name: "Haus",
		qtyProp: "shopify_haus_qty",
		priceProp: "haus_price",
	},
	{
		id: refConst.id_store_eBay_EIO,
		logo: logoStoreEbay,
		name: "eBay",
		qtyProp: "ebay_qty",
		priceProp: "ebay_price",
	},
	{
		id: refConst.id_store_walmart_eio,
		logo: logoStoreWalmart,
		name: "Walmart",
		qtyProp: "walmart_qty",
		priceProp: "walmart_price",
	},
	{
		id: refConst.id_store_amazon_EIO,
		logo: logoStoreAmazon,
		name: "Amazon",
		qtyProp: "amazon_us_eio_qty",
		priceProp: "amazon_us_eio_price",
	},
	{
		id: refConst.id_store_amazon_GOTT,
		logo: logoStoreAmazonGott,
		name: "Amazon",
		qtyProp: "amazon_us_gott_qty",
		priceProp: "amazon_us_gott_price",
	},
]

function fnc(props) {

	const [priceHistoryPerStore, setPriceHistoryPerStore] = useState(Object.assign({}, initialState.priceHistoryPerStore));

	useEffect(() => {
		initialState._isMounted = true;

		getPriceHistory({"id_product": props.id_product});

		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {

		props.setRefresher(Math.random())

	}, [priceHistoryPerStore])

	const getPriceHistory = (obj) => {


		fetchPriceHistory(obj.id_product, (r) => {
			console.info('Fetched fetchPriceHistory:', r);

			if(r.errors){
				props.info({
					error: r.errors
				})
				setPriceHistoryPerStore(Object.assign({}, initialState.priceHistoryPerStore))
			}else{
				let listPerStore = {}
				r.data.forEach(o => {
					listPerStore[props.item.id_marketplace] = o
				})
				setPriceHistoryPerStore(listPerStore)
			}
		});
	}
	
	return (props.sectionBuilder("Quantities & Prices", () => {
		return <div>
			<p className="mb-1 font-weight-bold">QOH: {parseInt(props.item.qty_on_hand)>0? props.item.qty_on_hand : 0}</p>
			<table className="fontNumber font-weight-bold">
				<thead>
					<tr>
						{
							listStoreInfo.map((stO, i) => {
								return <th scope="col" key={`th_${stO.id}`} className="text-center"><img src={stO.logo} className="" style={{height: "35px"}}></img></th>
							})
						}
					</tr>
				</thead>
				<tbody>
					<tr>
						{
							listStoreInfo.map((stO, i) => {

								if(priceHistoryPerStore[stO.id] && priceHistoryPerStore[stO.id].prices[0] != o[stO.priceProp]){
									priceHistoryPerStore[stO.id].prices.unshift(o[stO.priceProp])
									priceHistoryPerStore[stO.id].dates.unshift("Current")
								}

								return <td className="text-center smallText" key={`td_${stO.id}`}>
									<div>{ props.item.qtyProp? o[stO.qtyProp] || 0 : "" }</div>
									<div className="pl-2 pr-2">
										{
											priceHistoryPerStore[stO.id]? <select style={{width: "100px", outline: "none", fontWeight: "bold"}}>
												{
													priceHistoryPerStore[stO.id].prices.map((price, pos) => {
														return <option key={`Quantities_${stO.id} - ${pos}`} value={""}>${price} - {priceHistoryPerStore[stO.id].dates[pos]}</option>
													})
												}
											</select> : <span>${ props.item[stO.priceProp] || 0 }</span>
										}
									</div>
								</td>
							})
						}
					</tr>
				</tbody>
			</table>
		</div>
	}, {
		minWidth: "280px"
	}));
}

export default fnc