// eslint-disable-next-line no-unused-vars
import React, {  } from 'react'
import {} from "../../../fnc.js"

const refConst = require("../../../constants.js").v

var initialState = {
}

function fnc(props) {
	
	return (props.sectionBuilder("System Tags", () => {

		let tags = [
			{
				name: "[Walmart] Do not auto create",
				tag: "do_not_create_on_walmart"
			},
			{
				name: "Deleted",
				tag: "deleted"
			},
			{
				name: "Active",
				tag: "is_active"
			},
			{
				name: "No Auto Lock",
				tag: "no_auto_lock"
			},
		]

		return <div>
			{
				tags.filter((t, i) => {
					if(props.item[t.tag] && parseInt(props.item[t.tag]) > 0)	return t
				}).map(t => {
					
					return <span key={`${t.tag}_badge_system`} className="badge badge-primary p-2 ml-1 mr-1 mt-2">{t.name}</span>

				})
			}
		</div>
	}, {
		minWidth: "280px"
	}));
}

export default fnc