// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import Loading from './Loading';
import { fetchMenu, menuOrganized, capitalizeFLetter, fetchBrands, ajaxPerso, socketPerso, findWherePosted, uniqueArray } from '../../fnc'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { utils, writeFile } from 'xlsx';
import moment from 'moment';

const refConst = require("../../constants.js").v

var initialState = {
	storeFrom: null,
	masterVendorSelected: null,
	storeTo: null,
	itemsValid: [],
	items: [],
	categoriesStoreTo: {},
	brandsStoreTo: [],
	itemsCreated: {},
	submited: false,
	_isMounted: false
}

export default function CreateItem(props){

	const [masterVendorSelected, setMasterVendorSelected] = useState(initialState.masterVendorSelected);
	const [submited, setSubmited] = useState(initialState.submited);
	const [itemsCreated, setItemsCreated] = useState(Object.assign({}, initialState.itemsCreated));
	const [brandsStoreTo, setBrandsStoreTo] = useState(initialState.brandsStoreTo);
	const [storeFrom, setStoreFrom] = useState(initialState.storeFrom);
	const [storeTo, setStoreTo] = useState(initialState.storeTo);
	const [itemsValid, setItemsValid] = useState(initialState.itemsValid.slice(0));
	const [items, setItems] = useState(initialState.items.slice(0));
	const [categoriesStoreTo, setCategoriesStoreTo] = useState(initialState.categoriesStoreTo);
	const [version, setVersion] = useState("0.4.0");

	useEffect(() => {
		initialState._isMounted = true;

		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {
		console.info('DEBUG', [storeFrom, itemsValid]);
		
		if(storeFrom && itemsValid.length > 0)
			getItems(storeFrom, itemsValid);
	}, [storeFrom, itemsValid])

	useEffect(() => {
		if(props.taskDone){
			console.info("restaskDone", props.taskDone);
		}
	}, [props.taskDone])

	useEffect(() => {
		modaleCreateItem(true);
	}, [categoriesStoreTo, brandsStoreTo, storeFrom, storeTo, itemsValid, items, submited, itemsCreated])

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				let fncName = capitalizeFLetter(key)
				if("_isMounted" != fncName)
					console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}
	
	let createItem = (id_store_from, id_store_to) => {

		props.loading(false)
		setSubmited(false)
		let itemsSelected = props.itemsSelected
		if(itemsSelected.length === 0)
			return false

		findWherePosted({
			"idList": itemsSelected.join(',')
		}, (r) => {
			if(!initialState._isMounted)	return false

			console.info('Fetched presence:', r);
			if(r.success){

				let listMarketAv = getStoreWhereNeeded(r.data || [])
				let itemsOutList = [];

				console.info("listMarketAv", listMarketAv);
				
				if(listMarketAv && listMarketAv[id_store_to]){
					let itemsFrom = listMarketAv[id_store_from], // Can be undefined if all items selected are posted in the marketplace from.
						itemsTo = listMarketAv[id_store_to]

					console.info('itemsFrom', itemsFrom);
					console.info('itemsTo', itemsTo);
					
					// Make sure to keep items that need to be created to the marketplace selected and that are also present from the marketplace from.
					itemsTo.forEach(idItemTo => {
						if(!itemsFrom || itemsFrom.indexOf(idItemTo) === -1)	itemsOutList.push(idItemTo)
					})
				}

				setStoreFrom(id_store_from)
				setStoreTo(id_store_to)
				setItemsValid(itemsOutList)
			}
		})

		modaleCreateItem(false);
	}

	let dlDetails = () => {

		var book  = utils.book_new()

		let errors = [],
			catErrors = [],
			successCreation = [];
		items.forEach(o => {
			if(o.catError){
				catErrors.push({
					"id_item": o.id,
					"sku": o.sku,
					"vendor": o.vendor,
					"error": o.catError
				})
			}
			if(o.errors){
				errors.push({
					"id_item": o.id,
					"sku": o.sku,
					"vendor": o.vendor,
					"errors": o.errors.join()
				})
			}
			if(o.successCreation){
				successCreation.push({
					"id_item": o.id_new_item?? null,
					"eio_from_id": o.id,
					"shopify_from_id": o.id_product_shopify,
					"sku": o.sku,
					"vendor": o.vendor
				})
			}
		})

		/* console.info("111catErrors", catErrors);
		console.info("111errors", errors);
		return; */

		if(errors.length){
			utils.book_append_sheet(book, utils.json_to_sheet(errors), "Errors");
		}
		if(catErrors.length){
			utils.book_append_sheet(book, utils.json_to_sheet(catErrors), "Mapping Category Errors");
		}
		if(successCreation.length){
			utils.book_append_sheet(book, utils.json_to_sheet(successCreation), "Success");
		}
		
		if(errors.length > 0 || catErrors.length > 0 || successCreation.length > 0)
			writeFile(book, `Result_${storeFrom}_to_${storeTo}_cloning_shopify_to_ebay_${moment().local().format('YYYY-MM-DD hh:mm:ss')}.xlsx`)
	}

	let modaleCreateItem = (refresh) => {
		props.modal({
			show: refresh? false : true,
			title: <span>Clone an item <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			options: {
				width: "modal-xl"
			},
			html: (popup) => {
				console.info('Item ready for that store', items);

				let objPreSelected = refConst.shopList.find(obj => {
					if(obj.storeId === storeTo)
						return obj
				})

				let errors = [],
					catErrors = [],
					successCreation = [];
				items.forEach(o => {
					if(o.catError)	catErrors.push(o.catError)
					if(o.errors)	errors.push(o.errors)
					if(o.successCreation)	successCreation.push(o.successCreation)
				})
				
				return storeTo === null? <Loading loading={true} centered={true}/> : <div>
					<p className="h5 mb-4">Item compatible for the selected marketplace: {items.length}</p>
					{ [refConst.id_store_eBay_EIO].indexOf(storeTo) !== -1? <div className="alert alert-warning" role="alert">
						Please make sure that the product exist in finale, please allow 1 day after creation in finale for this product to be cloned with this tool.
					</div> : "" }
					<div>
						<div className={(errors.length || catErrors.length || successCreation.length ? "d-flex" : "d-none") + " flex-row align-items-center p-2"}>
							<div>
								<div>Item with Errors: {errors.length}</div>
								<div>Mapping Category Errors: {catErrors.length}</div>
								<div>Success: {successCreation.length}</div>
							</div>
							<div>
								<button className="btn btn-primary ml-3" onClick={dlDetails}>Download Details</button>
							</div>
						</div>
						<table className="table">
							<thead>
								<tr>
									<th scope="col" className="bg-info text-white">Id</th>
									<th scope="col" className="bg-info text-white">Brand</th>
									<th scope="col" className="bg-info text-white">SKU</th>
									<th scope="col" className="bg-info text-white">Title</th>
									{submited? <th scope="col" className="bg-primary text-white">Status</th> : null}
								</tr>
							</thead>
							{
								items.length > 0?
									<tbody>
										{
											items.map((o, ii) => {
												let brandObj = brandsStoreTo.find(b => {
													if(b.name == o.vendor)	return	b
												})

												o.prefix = brandObj? brandObj.prefix : null; // brandObj && brandObj.prefix? o.sku_in_store.indexOf(brandObj.prefix) !== -1? o.sku_in_store.substr(brandObj.prefix.length) : o.sku_in_store : o.sku_in_store
												return	<tr key={`${o.id}_itemCreate`}>
													<th scope="row">{o.id}</th>
													<td>{o.vendor}</td>
													<td>{o.sku_in_store}</td>
													<td className="w-25 overflow-auto"><div style={{height: "30px"}}><a href={o.view_item_url_natural} target="_blank" rel="noopener noreferrer">{o.title}</a></div></td>
													{submited? <td>Status {
														"successCreation" in o || "errors" in o? <span>
															<FontAwesomeIcon style={{color: o.successCreation? "green" : "red"}} icon={o.successCreation? faCheckCircle : faMinusCircle} title={o.successCreation? "Active" : "Inactive"}/>
															<span className="badge badge-info ml-3 p-2">{o.id_new_item}</span>
														</span> : 'Loading...'
													}</td> : null}

												</tr>
											})
										}
									</tbody>
									: null
							}
						</table>
					</div>
				</div>
			}
			, exit: (popup, close) => {
				close()
				setSubmited(false)
			}
			, ok: !submited? {
				title: "Create Clones",
				fnc: (popup, close) => {
					props.loading(true)
					setSubmited(true)

					console.info("items", items);

					let objToSend = []
					items.forEach( o => {
						if(o.product_type)
							objToSend.push(o.id_product_shopify)
					})

					console.info('Items_send', objToSend);
					// return;

					reqCreatItems(storeTo, uniqueArray(objToSend), (r) => {
						if(!initialState._isMounted)	return false
						props.loading(false)
					})
					// resetPage()
					// popup.modal('hide')
				}
			} : false, return: {
				title: "Return",
				fnc: (popup, close) => {
					setSubmited(false)
					resetPage()
					close()
				}
			}
		});
	}

	let reqCreatItems = (storeId, itemIdList, callbackPresence) => {
		if(!storeId || !itemIdList)
			throw("Please indicate a storeId / itemIdList.");
		else{
			let ctnItem = itemIdList.length,
				i = 0;

			console.info('ctnItem', ctnItem);
			console.info('itemIdList 2', itemIdList);
			
			var intSend = setInterval(function(){

				if(ctnItem <= 0){
					console.info('ctnItem <= 0');
					props.loading(false)
					clearInterval(intSend)
					return false;
				}else{

					console.info('itemList[i]', itemIdList[i]);
					console.info('ctnItemctnItem', ctnItem);
					
					console.info('Has to send socket', {
						"api": "shopify",
						"trigger": "cloneFromOtherStore",
						"uid": props.getUser().uid,
						"shopIdFrom": storeFrom,
						"shopIdTo": storeTo,
						"itemIdList": itemIdList[i]
					});

					/* ajaxPerso( {
						"api": "shopify",
							"trigger": "cloneFromOtherStore",
							"uid": props.getUser().uid,
							"shopIdFrom": storeFrom,
							"shopIdTo": storeTo,
							"itemIdList": itemIdList[i]
					}, callbackPresence);
					clearInterval(intSend)
					return; */
					
					socketPerso("task", {
						url: refConst.urlServer,
						// token: refConst.tokenEio,
						internalReq: {
							"api": "shopify",
							"trigger": "cloneFromOtherStore",
							"uid": props.getUser().uid,
							"shopIdFrom": storeFrom,
							"shopIdTo": storeTo,
							"itemIdList": itemIdList[i]
						},
						task: {
							op: "insert",
							type: "shopify_product",
							store: storeId,
							args: {
								uid: props.getUser().uid,
								name: `Create Product for ${itemIdList[i]}`
							}
						}
					}, (passThrough) => {
						console.info("Res_socket", passThrough);

						if(passThrough.itemsErrorPerId){
							props.info({"error": passThrough.itemsErrorPerId})
						}
						if(passThrough.data){

							items.forEach((o, posItem) => {
								// Item Successfully inserted
								passThrough.data.forEach((pr, pos) => {
									// console.info(`CHECK ${o.id_product_shopify} ${pr.id_item}`);
									if("id_item" in pr && pr.id_item && pr.id_item == o.id_product_shopify){
										o.successCreation = pr.success
										if(pr.success && "id_new_item" in pr){
											o.id_new_item = pr.id_new_item
											return;
										}
									}
								})
								/* console.info("sadasdasdasdsad", Object.keys(passThrough.missingMapping));
								console.info("sadasdasdasdsad22", String(o.id_product_shopify)); */
								if(Object.keys(passThrough.missingMapping).indexOf(String(o.id_product_shopify)) !== -1){
									o.catError = passThrough.missingMapping[o.id_product_shopify]
								}
								if(Object.keys(passThrough.itemsErrorPerId).indexOf(String(o.id_product_shopify)) !== -1){
									o.errors = passThrough.itemsErrorPerId[o.id_product_shopify]
								}
							})
							setItems(items.slice(0))
						}
					})
					ctnItem--;
					i++;
				}

			}, Math.floor(1000)) // Shopify limit 2 requests for 1 seconde, so half second 500 Milliseconds
		}
	}

	let fetchItems = (storeId, idProductList, callbackPresence) => {
		if(!storeId || !idProductList || idProductList.length === 0)
			throw("Please indicate a list of product ID.");
		else{

			let apiName = false;
			apiName = [refConst.id_store_eBay_EIO].indexOf(storeId) !== -1? "ebay" : false
			if(!apiName) apiName = [refConst.id_store_eio, refConst.id_store_hausfTools].indexOf(storeId) !== -1? "shopify" : false
			if(!apiName) apiName = [refConst.id_store_amazon_EIO, refConst.id_store_amazon_GOTT].indexOf(storeId) !== -1? "amazon" : false
			if(!apiName) apiName = [refConst.id_store_walmart_eio].indexOf(storeId) !== -1? "walmart" : false

			ajaxPerso( {
				"api": apiName,
				"trigger": "getItems",
				"shop": storeId,
				"idProductListJson": JSON.stringify(idProductList),
			}, callbackPresence);
		}
	}

	let getItems = (storeId, idProductList) => {
		fetchItems(storeId, idProductList, (r) => {
			if(!initialState._isMounted)	return false

			if(r.success){
				console.info('Fetched Ebay Items', r);
				let listId = uniqueArray(r.data.map(o => o.id_product_shopify))
				console.info("list Id wanted", listId);
				let idAlreadyIn = []
				setItems(r.data.filter((o) => {
					if(idAlreadyIn.indexOf(o.id_product_shopify) === -1){
						idAlreadyIn.push(o.id_product_shopify)
						return o;
					}
				}))
			}else{
				props.info({"error": r.error})
			}
		})
	}
	

	let getStoreWhereNeeded = (itemInStore) => {

		/**
			Return {
				idStore1: [idItemToCreate], idStore2: [idItemToCreate] ...
			}
		*/

		let listStoreAllowedToCreate = {};

		itemInStore.forEach(o => {
			props.stores.forEach(s => {
				if(o.list_id_store.length > 0){
					// not existing yet in this store
					if(o.list_id_store.indexOf(s.id) === -1){
						if(!listStoreAllowedToCreate[s.id])	listStoreAllowedToCreate[s.id] = []
						listStoreAllowedToCreate[s.id].push(o.id)
					}
				}else{
					if(!listStoreAllowedToCreate[s.id])	listStoreAllowedToCreate[s.id] = []
					listStoreAllowedToCreate[s.id].push(o.id)
				}
			})
		})

		console.info('itemInStore', itemInStore);
		console.info('listStoreAllowedToCreate', listStoreAllowedToCreate);
		return listStoreAllowedToCreate
	}

	let storeFromToNames = () => {
		if(props.stores.length === 0)	return ""
		
		let storeTo, storeFrom = null;
		storeTo = props.stores.find(o => {
			if(o.id === props.storeTo)	return o
		})
		storeFrom = props.stores.find(o => {
			if(o.id === props.storeFrom)	return o
		})

		return storeFrom.name + " -> " + storeTo.name
	}

	console.info("itemsitemsitems", items);

	return (
		<div className="list-group-item list-group-item-action p-2 text-center" onClick={() => createItem(props.storeFrom, props.storeTo)}>
			{
				storeFromToNames()
			}
		</div>
	);
}