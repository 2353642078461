// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import {loadMinSellingPrices, fetchAvgPerSku} from "../../../fnc.js"
import moment from 'moment';

const refConst = require("../../../constants.js").v

var initialState = {
	lastFewSale: null,
}

function fnc(props) {

	const [lastFewSale, setLastFewSale] = useState(initialState.lastFewSale);

	useEffect(() => {

		props.setRefresher(Math.random())

	}, [lastFewSale])

	useEffect(() => {
		
		let o = props.item
		
		if(o)
			fetchAvgPerSku({
				"sku": o.sku,
				"ship_start": moment().subtract(1, 'year').format("YYYY-MM-DD"),
				"packageId": 198, // package
				"qty": 1,
				"international": false
			}, (r) => {
				console.info('Last_Few_Sales:', r);
				if(r.success){
					props.loading(false)

					var sum = 0
					for( var i = 0; i < r.data.length; i++ ){
						sum += parseFloat( r.data[i].shipment_cost, 10 ) //don't forget to add the base
					}
					var avg = sum/r.data.length
					
					setLastFewSale(r.data.length > 0? {"data": r.data, "avg": avg} : initialState.lastFewSale)
				}else if(r.error){
					props.info({
						error: r.error
					})
					setLastFewSale(initialState.lastFewSale)
				}
			})
		
	}, [props.item])

	let htmlPrices = [];
	if(lastFewSale)
		lastFewSale.data.forEach(o => {

			htmlPrices.push(<tr key={`lastFewSale - ${o.id_order}`}>
				<td className="smallText">{o.ordered_at}</td>
				<td>${o.shipment_cost}</td>
				<td>{o.id_order}</td>
				<td>{o.state}</td>
				<td>{o.store_name}</td>
			</tr>)
		});
	
	return (htmlPrices.length > 0? props.sectionBuilder("Last few sales", () => {

		if(!lastFewSale)	return;

		console.info("lastFewSale", lastFewSale);

		

		return <div className="smallText mt-1">
			<div>
				Average Shipping Cost: 
				{lastFewSale.avg? <span className="font-weight-bold ml-2">${lastFewSale.avg.toFixed(3)}</span> : ""}
				<span className="ml-2">Since: {moment().subtract(1, 'year').format("YYYY-MM-DD")}</span>
			</div>
			<div className="mt-1" style={{
				maxHeight: "150px",
				overflowY: "scroll",
				boxShadow: "0px 3px 4px #a2a2a2 inset"
			}}>
				<table className="table table-sm table-bordered">
					<thead>
						<tr>
							<th scope="col" className="text-center">Date</th>
							<th scope="col" className="text-center">Cost</th>
							<th scope="col" className="text-center">Order</th>
							<th scope="col" className="text-center">State</th>
							<th scope="col" className="text-center">Store</th>
						</tr>
					</thead>
					<tbody>
						{
							htmlPrices
						}
					</tbody>
				</table>
			</div>
		</div>
	}, {
		minWidth: "280px"
	}) : "");
}

export default fnc