// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import {loadMinSellingPrices, fetchAvgPerSku} from "../../../fnc.js"

const refConst = require("../../../constants.js").v

var initialState = {
	minPrices: null,
}

function fnc(props) {

	const [minPrices, setMinPrices] = useState(initialState.minPrices);

	useEffect(() => {

		props.setRefresher(Math.random())

	}, [minPrices])

	useEffect(() => {
		
		let o = props.item
		
		if(o && o.weight && o.weight_unit && o.cost && o.sku){
					
			loadMinSellingPrices([{
				weight: o.weight,
				weight_unit: o.weight_unit,
				sku: o.sku,
				cost:  parseFloat(o.cost),
			}], (r) => {
				console.info('Min Selling Prices:', r);

				setMinPrices(r.success && r.data.length > 0? r.data[0] : null)
			});
		}
		
	}, [props.item])
	
	return (props.sectionBuilder("Min Selling Prices", () => {

		if(!minPrices)	return;

		let listStorePerId = []
		refConst.stores.forEach(o => {
			listStorePerId[o.id] = o
		});

		console.info("listStorePerIdlistStorePerId", listStorePerId);
		console.info("minPricesminPrices", minPrices);

		let htmlPrices = [];
		for (const idStore in minPrices.minSellingPrice) {
			if (Object.hasOwnProperty.call(minPrices.minSellingPrice, idStore)) {
				const minPrice = minPrices.minSellingPrice[idStore];
				htmlPrices.push(<div key={`htmlPrices - ${idStore}`} className="d-flex justify-content-between">
					<span className="smallText">- {listStorePerId[idStore].name}</span>
					<span>${minPrice}</span>
				</div>)
			}
		}

		return <div>
			{
				htmlPrices
			}
		</div>
	}, {
		minWidth: "280px"
	}));
}

export default fnc