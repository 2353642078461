// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import { ajaxPerso, getSupplier, getSupplierInfo, setIntervalV2, fetchApiData, fetchValueInventory, adjustColor } from '../../../fnc'
import {
	NavLink
} from 'react-router-dom';
import store from 'store2';
import Select from 'react-select';
import loading from '../../../Rolling.svg';
import ModalModifySupplier from '../ModalModifySupplier.js';
import moment from 'moment';
import Chart from 'chart.js/auto';
import ManualDateRangeModal from './ManualDateRangeModal.js';
// import { Line } from 'react-chartjs-2';

import Loading from "../Loading.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
const refConst = require("../../../constants.js").v

/* ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
); */

var initialState = {
	_isMounted: false,
	selectedSupplier: null,
	supplierInfo: null,
	poData: null,
	inventoryCount: null,
	daysHistory: null,
	selectedStoreName: null,
	colorPerStoreId: [],
	percentIncrease: null,
	trueMarginData: null,
}

const styleList = {
	menu: (provided) => ({
		...provided,
		zIndex: 99999,
	}),
	container: (provided) => ({
		...provided,
		width: "100%",
		minWidth: "600px"
	}),
	control: provided => ({
		...provided,
		marginBottom: 0,
	}),
	multiValue: provided => ({
		...provided,
		padding: 0,
		border: "1px solid #6c6c6c",
	}),

}

export default function ManufacturerMain(props){

	var storeSelectedSupplier = store(`selectedSupplier_${props.pos}`)

	const [selectedSupplier, setSelectedSupplier] = useState(initialState.selectedSupplier);
	const [supplierInfo, setSupplierInfo] = useState(initialState.supplierInfo);
	const [poData, setPoData] = useState(initialState.poData);
	// const [poDataPrevYear, setPoDataPrevYear] = useState(initialState.poData);
	const [inventoryCount, setInventoryCount] = useState(initialState.inventoryCount);
	
	const [historyValueOrderList, setHistoryValueOrderList] = useState(null);
	const [suppliersSelectedValue, setSuppliersSelectedValue] = useState([]);

	const [historyValueList, setHistoryValueList] = useState([]);
	const [historyValueListPastYear, setHistoryValueListPastYear] = useState([]);
	const [chartData, setChartData] = useState(null);
	const [storeSalesList, setStoreSalesList] = useState([]);
	const [storeSalesListPast, setStoreSalesListPast] = useState([]);
	const [daysHistory, setDaysHistory] = useState(initialState.daysHistory);
	const [selectedStoreName, setSelectedStoreName] = useState(initialState.selectedStoreName);
	const [colorPerStoreId, setColorPerStoreId] = useState(initialState.colorPerStoreId);
	const [percentIncrease, setPercentIncrease] = useState(initialState.percentIncrease); // When null, it means loading
	const [trueMarginData, setTrueMarginData] = useState(initialState.trueMarginData); // When null, it means loading
	// const [dateFrom, setDateFrom] = useState(moment("20230201", "YYYYMMDD").toDate());
	// const [dateTo, setDateTo] = useState(moment("20230831", "YYYYMMDD").toDate());
	const [dateFrom, setDateFrom] = useState(null);
	const [dateTo, setDateTo] = useState(null);
	const [compareToPrevTime, setCompareToPrevTime] = useState(true);

	useEffect(() => {
		console.info('refConst', refConst);
		
		initialState._isMounted = true;
		
		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {

		if(!daysHistory/*  && (!dateFrom && !dateTo) */)
			setDaysHistory(30)

		if(!("charts" in window))
			window.charts = []
		if(!("chartsValue" in window))
			window.chartsValue = []

		console.info("Init initialized");

		let listColorPerStoreId = []
		refConst.stores.forEach((o) => {
			listColorPerStoreId[o.name] = o.color
		})
		setColorPerStoreId(listColorPerStoreId)
		
	}, [])

	useEffect(() => {
		// Preload the supplier previously selected if we reload the page.
		if(storeSelectedSupplier !== null && props.suppliers[storeSelectedSupplier]){
			console.info("SSSSSSADASDASD", storeSelectedSupplier);
			setSelectedSupplier(storeSelectedSupplier)
		}
	}, [props.suppliers])

	useEffect(() => {
		
		if(selectedSupplier !== null && props.suppliers){
			let spSel = props.suppliers[selectedSupplier]
			
			if(spSel)
				setSuppliersSelectedValue([spSel.name])
		}

	}, [selectedSupplier, props.suppliers])

	useEffect(() => {

		// Here we need to calculate the percent increase for the selected period
		if(storeSalesList.length > 0 && storeSalesListPast.length > 0){

			let totalCurrent = storeSalesList.filter(s => {
				if(selectedStoreName && selectedStoreName != s.storeName)	return false;
				return true;
			}).reduce((a, b) => a + b.total, 0)

			let totalPast = storeSalesListPast.filter(s => {
				if(selectedStoreName && selectedStoreName != s.storeName)	return false;
				return true;
			}).reduce((a, b) => a + b.total, 0)

			let percent = totalPast > 0? Math.round(((totalCurrent - totalPast) / totalPast) * 100) : 100
			setPercentIncrease(percent)
		}else if(storeSalesList.length > 0 && storeSalesListPast.length == 0){
			setPercentIncrease(100)
		}

	}, [storeSalesList, storeSalesListPast, selectedStoreName])

	/**
	 * Show only label and data from selected sku in the list
	 */
	let filtersFnc = (legend, chartData) => {
		console.info("sadasdasd", legend);
		console.info("sadasdasd32", chartData);
		return true
	}

	useEffect(() => {

		console.info("historyValueList22", historyValueList);
		console.info("colorPerStoreId22", colorPerStoreId);

		if(dateFrom && dateTo)	return;
		if(storeSelectedSupplier === null)	return;
		
		if(document.querySelector(`#graphHistoryInventoryValue_${props.pos}`) && historyValueList && Object.keys(colorPerStoreId).length > 0){
		// if(historyValueList && Object.keys(colorPerStoreId).length > 0){

			let labelBottom = [];
			let itemsGraphData = [];
			let color = false;
			let perStore = {};
			let perStorePerDate = {};

			// Create the date group base on the filters
			let dateType = 'days'
			let historyGrpBy = daysHistory
			if(180 == daysHistory){
				historyGrpBy = 6
				dateType = 'months'
			}else if(365 == daysHistory){
				historyGrpBy = 12
				dateType = 'months'
			}

			let date = moment();
			for (let i = historyGrpBy-1; i >= 0; i--) {

				labelBottom.push(date.format('YYYY/MM/DD'))
				date = date.subtract(1, dateType);
			}
			labelBottom = labelBottom.reverse()

			// Organise the values per date grouped from the filtering
			historyValueList.forEach((o) => {
				
				if(!(o.customer in perStorePerDate))
					perStorePerDate[o.customer] = {}

				let rDateOrder= moment(o.order_date)
				labelBottom.forEach((dateLabel, posDate) => {
					let rDateLabel = moment(dateLabel)

					if(!(dateLabel in perStorePerDate[o.customer]))
						perStorePerDate[o.customer][dateLabel] = 0.0

					if(rDateOrder.isAfter(rDateLabel) && rDateOrder.isBefore(rDateLabel.add(1, dateType)) || rDateOrder.isSame(rDateLabel)){
						perStorePerDate[o.customer][dateLabel] += parseFloat(o.sub_total)
					}
				})
			})

			/* console.info("labelBottomlabelBottom", labelBottom);
			console.info("perStorePerDateperStorePerDate", perStorePerDate); */
			// return;

			// Convert the object date to value to just a list of value for the graph
			for (const store in perStorePerDate) {
				if (perStorePerDate.hasOwnProperty(store)) {

					// limit the decimal to 2
					for (const date in perStorePerDate[store]) {
						if (Object.hasOwnProperty.call(perStorePerDate[store], date)) {
							perStorePerDate[store][date] = parseFloat(perStorePerDate[store][date].toFixed(2))
						}
					}

					perStorePerDate[store] = Object.values(perStorePerDate[store])
				}
			}
			perStore = perStorePerDate

			console.info('brandValue', perStore);
			
			for (const store in perStore) {
				if (perStore.hasOwnProperty(store)) {

					if(selectedStoreName && selectedStoreName != store)	continue;

					color = colorPerStoreId[store]

					console.info("colorcolor", color);

					let salesInfo = storeSalesList.find((o) => {
						if(o.storeName == store){
							return true
						}
					})

					itemsGraphData.push({
						label: store + (salesInfo? ` (${salesInfo.percent}%)` : ""),
						data: perStore[store],
						backgroundColor: color,
						fill: false,
						borderColor: color,
						// hidden: !isVisible,
						// spanGaps: false,
						// tension: 0.3,
						tension: 0.1,
						// stepped: true,
						pointRadius: 1
					})
				}
			}

			console.info('itemsGraphData', itemsGraphData);
		
			if(window.charts[props.pos])
				window.charts[props.pos].destroy()
				
				/* console.warn("Create new one", {
					labels: labelBottom,
					datasets: itemsGraphData,
				}); */
				
				window.charts[props.pos] = new Chart(document.querySelector(`#graphHistoryInventoryValue_${props.pos}`), {
					type: 'line',
					data: {
						labels: labelBottom,
						datasets: itemsGraphData,
					},
					options: {
						plugins: {
							legend: {
								align: 'center',
								labels: {
									filter: filtersFnc
								}
							},
							tooltips: {
								mode: 'index',
								intersect: false,
								callbacks: {
									label: function(tooltipItem, data) {
										var label = data.datasets[tooltipItem.datasetIndex].label;
										var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
										return label + ': ' + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);  // Format the label and value here
									}
								}
							}
						},
						aspectRatio: props.doubled && props.inlineView? 3 : 4,
						/* elements: {
							line: {
								tension: 0.4  // Set this to the desired line tension
							}
						}, */
						scales: {
							y: {
								ticks: {
									callback: function(value, index, values) {
										if(value >= 1000){
											return value / 1000 + 'k';
										} else {
											return value;
										}
									},
									autoSkip: true,
									maxTicksLimit: 5
								}
							},
							x: {
								ticks: {
									autoSkip: true,
									maxTicksLimit: [180, 365].indexOf(daysHistory) !== -1? historyGrpBy : 7,
									callback: function(value, index, values) {
										// Format the value here and return it
										/* console.info("valuevalue", value);
										console.info("indexindex", index);
										console.info("valuesvalues", values); */
										return moment(labelBottom[index]).format('MMM D YY');
									}
									// max: labelBottom[0],
								}
							}
						},
						interaction: {
							mode: 'index',
							intersect: false
						},
						spanGaps: true,
					}
				})
			// }else{
			// 	console.warn("Update chart");

			// 	//chart.destroy()
			// 	chart.data = {
			// 		labels: labelBottom,
			// 		datasets: itemsGraphData,
			// 	};
			// 	chart.options.plugins.legend.labels.filter = filtersFnc;
			// 	chart.update();
			// 	setChart(chart)
			// }
		}
	}, [historyValueList, selectedStoreName, colorPerStoreId, daysHistory, props.selectedSuppliers, props.inlineView, storeSalesList])

	const organiseDataForGraph = (data, labelBottom, dateType) => {

		let perStorePerDate = {};

		// Organise the values per date grouped from the filtering
		data.forEach((o) => {
						
			if(!(o.customer in perStorePerDate))
				perStorePerDate[o.customer] = {}

			let rDateOrder= moment(o.order_date)
			labelBottom.forEach((dateLabel, posDate) => {
				let rDateLabel = moment(dateLabel)

				if(!(dateLabel in perStorePerDate[o.customer]))
					perStorePerDate[o.customer][dateLabel] = 0.0

				if(rDateOrder.isAfter(rDateLabel) && rDateOrder.isBefore(rDateLabel.add(1, dateType)) || rDateOrder.isSame(rDateLabel)){
					perStorePerDate[o.customer][dateLabel] += parseFloat(o.sub_total)
				}
			})
		})

		/* console.info("labelBottomlabelBottom", labelBottom);
			console.info("perStorePerDateperStorePerDate", perStorePerDate); */
			// return;

		// Convert the object date to value to just a list of value for the graph
		for (const store in perStorePerDate) {
			if (perStorePerDate.hasOwnProperty(store)) {

				// limit the decimal to 2
				for (const date in perStorePerDate[store]) {
					if (Object.hasOwnProperty.call(perStorePerDate[store], date)) {
						perStorePerDate[store][date] = parseFloat(perStorePerDate[store][date].toFixed(2))
					}
				}

				perStorePerDate[store] = Object.values(perStorePerDate[store])
			}
		}
		return perStorePerDate;
	}

	const createGraphData = (graphData, storeSales) => {

		let itemsGraphData = []

		for (const store in graphData) {
			if (graphData.hasOwnProperty(store)) {

				if(selectedStoreName && selectedStoreName != store)	continue;

				let color = colorPerStoreId[store]
				console.info("colorcolor", color);

				let salesInfo = storeSales.find((o) => {
					if(o.storeName == store){
						return true
					}
				})

				itemsGraphData.push({
					label: store + (salesInfo? ` (${salesInfo.percent}%)` : ""),
					data: graphData[store],
					backgroundColor: color,
					fill: false,
					borderColor: color,
					// hidden: !isVisible,
					// spanGaps: false,
					// tension: 0.3,
					tension: 0.1,
					// stepped: true,
					pointRadius: 1
				})
			}
		}
		return itemsGraphData
	}

	useEffect(() => {
		/**
		 * Show the special selection with the previous year result
		 */
		/* console.info("storeSalesList22-", storeSalesList);
		console.info("historyValueList22-", historyValueList);
		console.info("historyValueList22-", historyValueListPastYear);
		console.info("colorPerStoreId22-", colorPerStoreId); */

		if(!dateFrom || !dateTo)	return;
		if(storeSelectedSupplier === null)	return;
		
		if(document.querySelector(`#graphHistoryInventoryValueSelect_${props.pos}`) && historyValueList && Object.keys(colorPerStoreId).length > 0){
		// if(historyValueList && Object.keys(colorPerStoreId).length > 0){

			let labelBottom = [];

			// Calculate how many days between the dateFrom and dateTo with moment
			let daysDiff = moment(dateTo).diff(moment(dateFrom), 'days')

			// Create the date group base on the filters
			let dateType = 'weeks'
			let historyGrpBy = Math.round(daysDiff / 7) + 1

			if(daysDiff <= 30){
				historyGrpBy = daysDiff+1
				dateType = 'days'
			}

			let date = moment(dateTo);
			for (let i = historyGrpBy-1; i >= 0; i--) {

				labelBottom.push(date.format('YYYY/MM/DD'))
				date = date.subtract(1, dateType);
			}
			labelBottom = labelBottom.reverse()

			console.info("labelBottom333-", labelBottom);
			console.info("historyGrpBy333-", historyGrpBy);
			console.info("historyValueList333-", historyValueList);
			console.info("historyValueListPastYear333-", historyValueListPastYear);

			let graphData = organiseDataForGraph(historyValueList, labelBottom, dateType)
			// Remove one year at all the labels
			let labelBottomPrev = [];
			labelBottom.forEach((o, i) => {
				labelBottomPrev[i] = moment(o).subtract(1, 'year').format('YYYY/MM/DD')
			})
			let graphDataPast = organiseDataForGraph(historyValueListPastYear, labelBottomPrev, dateType)

			let itemsGraphData = createGraphData(graphData, storeSalesList)
			let itemsGraphDataPast = createGraphData(graphDataPast, storeSalesListPast)

			console.info('labellabel', labelBottom);
			console.info('brandValue', graphData);
			console.info('itemsGraphData', itemsGraphData);
			console.info('itemsGraphDataPast', itemsGraphDataPast);
			console.info('graphDataPastgraphDataPast', graphDataPast);

			// Make the data individuel for the graph (the history for the prev year)
			itemsGraphDataPast.forEach((o) => {
				o.label += " - Prev Year"
				let color = adjustColor(o.backgroundColor, 1, 1, 16)
				o.backgroundColor = selectedStoreName? "#757575" : color
				o.borderColor = selectedStoreName? "#757575" : color
			})

			// Merge the 2 data set
			itemsGraphData = itemsGraphData.concat(itemsGraphDataPast)
		
			if(window.charts[props.pos])
				window.charts[props.pos].destroy()
			
			window.charts[props.pos] = new Chart(document.querySelector(`#graphHistoryInventoryValueSelect_${props.pos}`), {
				type: 'line',
				data: {
					labels: labelBottom,
					datasets: itemsGraphData,
				},
				options: {
					plugins: {
						legend: {
							align: 'center',
							labels: {
								filter: filtersFnc
							}
						},
						tooltip: {
							mode: 'index',
							intersect: false,
							callbacks: {
								label: function(data) {
									var label = data.dataset.label;
									var value = data.dataset.data[data.dataIndex];
									return label + ': ' + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)  // Format the label and value here
								}
							},
							itemSort: function(a, b) {
								const labelComparison = a.dataset.label.localeCompare(b.dataset.label);
								if (labelComparison !== 0) {
									return labelComparison; // If labels are not equal, return the result of the label comparison.
								}
								// If labels are equal, compare by raw value. Adjust the comparison for ascending or descending order as needed.
								// For ascending order based on raw value:
								return a.raw - b.raw;
							},
						}
					},
					aspectRatio: props.doubled && props.inlineView? 3 : 4,
					/* elements: {
						line: {
							tension: 0.4  // Set this to the desired line tension
						}
					}, */
					scales: {
						y: {
							ticks: {
								callback: function(value, index, values) {
									if(value >= 1000){
										return value / 1000 + 'k';
									} else {
										return value;
									}
								},
								autoSkip: true,
								maxTicksLimit: 5
							}
						},
						x: {
							ticks: {
								autoSkip: true,
								maxTicksLimit: [180, 365].indexOf(daysHistory) !== -1? historyGrpBy : 7,
								callback: function(value, index, values) {
									return moment(labelBottom[index]).format('MMM D YY');
								}
								// max: labelBottom[0],
							}
						}
					},
					interaction: {
						mode: 'index',
						intersect: false
					},
					spanGaps: true,
				}
			})
		}
	}, [historyValueList, historyValueListPastYear, selectedStoreName, colorPerStoreId, props.selectedSuppliers, props.inlineView, dateFrom, dateTo, compareToPrevTime])
	
	useEffect(() => {

		console.info("historyValueOrderList22", historyValueOrderList);
		console.info("historyValueOrderList33", suppliersSelectedValue);
		console.info("historyValueOrderList44", `#graphHistoryInventoryOrderValue_${props.pos}`);

		if(document.querySelector(`#graphHistoryInventoryOrderValue_${props.pos}`) && historyValueOrderList && suppliersSelectedValue.length > 0){

			let labelBottom = [];
			let itemsGraphData = [];

			// Create the dfate group base on the filters
			let dateType = 'days'
			let historyGrpBy = daysHistory
			if(180 == daysHistory){
				historyGrpBy = 6
				dateType = 'months'
			}else if(365 == daysHistory){
				historyGrpBy = 12
				dateType = 'months'
			}

			historyValueOrderList.data.forEach((o) => {
				if(labelBottom.indexOf(moment(o.date).format('YYYY-MM-DD')) === -1)
					labelBottom.push(moment(o.date).format('YYYY-MM-DD'))
			})

			suppliersSelectedValue.map((supplierNameWanted, pos) => {
				let listData = {}

				historyValueOrderList.data.forEach((o) => {
					listData[o.date] = parseInt(o.obj[supplierNameWanted]) ?? 0
				})

				console.info("listDatalistData", listData);
				console.info("labelBottomlabelBottom", labelBottom);

				itemsGraphData.push({
					label: supplierNameWanted,
					data: listData,
					// backgroundColor: color,
					fill: false,
					// borderColor: color,
					// hidden: !isVisible,
					// spanGaps: false,
					// tension: 0.3,
					tension: 0.1,
					// stepped: true,
					pointRadius: 1
				})
			})
		
			if(window.chartsValue[props.pos])
				window.chartsValue[props.pos].destroy()
				
			console.warn("Create new one", {
				labels: labelBottom,
				datasets: itemsGraphData,
			});
			
			window.chartsValue[props.pos] = new Chart(document.querySelector(`#graphHistoryInventoryOrderValue_${props.pos}`), {
				type: 'line',
				data: {
					labels: labelBottom,
					datasets: itemsGraphData,
				},
				options: {
					plugins: {
						legend: {
							align: 'center',
							labels: {
								filter: filtersFnc
							}
						},
						tooltips: {
							mode: 'index',
							intersect: false,
							callbacks: {
								label: function(tooltipItem, data) {
									var label = data.datasets[tooltipItem.datasetIndex].label;
									var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
									return label + ': ' + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);  // Format the label and value here
								}
							}
						},
					},
					aspectRatio: props.doubled && props.inlineView? 4 : 5,
					/* elements: {
						line: {
							tension: 0.4  // Set this to the desired line tension
						}
					}, */
					scales: {
						y: {
							ticks: {
								callback: function(value, index, values) {
									if(value >= 1000){
										return value / 1000 + 'k';
									} else {
										return value;
									}
								},
								autoSkip: true,
								maxTicksLimit: 5
							}
						},
						x: {
							ticks: {
								autoSkip: true,
								maxTicksLimit: [180, 365].indexOf(daysHistory) !== -1? historyGrpBy : 7,
								callback: function(value, index, values) {
									// Format the value here and return it
									/* console.info("valuevalue", value);
									console.info("indexindex", index);
									console.info("valuesvalues", values); */
									return moment(labelBottom[index]).format('MMM D YY');
								}
								// max: labelBottom[0],
							}
						}
					},
					interaction: {
						mode: 'index',
						intersect: false
					},
					spanGaps: true,
				}
			})
		}
	}, [historyValueOrderList, suppliersSelectedValue, daysHistory, props.inlineView])

	const fetchSaleReport = (args, callback) => {
			
		ajaxPerso( Object.assign({
			"trigger": "fetch_sale_report",
			"api": "finale",
			// "top": top
		}, args), callback);
	}

	useEffect(() => {

		if(selectedSupplier === null)	return;

		props.loading(true)
		let awaitingCount = 3,
			doneCount = 0,
			limit = 500
		
		getSupplierInfo({
			"id_supplier": props.suppliers[selectedSupplier].id_finale,
		}, (r) => {
			doneCount++
			console.info('getSupplierInfo', r);
			setSupplierInfo(r.success && r.data? r.data[0] : initialState.supplierInfo)
		})

		let filters = {
			"id_supplier": props.suppliers[selectedSupplier].id_finale,
			"all": true
		}

		/* if(dateFrom && dateTo)
			filters.from = moment(dateFrom).format('YYYY-MM-DD')
			filters.to = moment(dateTo).format('YYYY-MM-DD') */

		// Load the previous year with the same date range
		/* if(compareToPrevTime && dateFrom && dateTo){
			fetchApiData(
				"finale",
				"fetch_most_recent_PO", filters, null,
				(r) => {
					console.info('fetch_most_recent_PO__prev__year', r);
					setPoDataPrevYear(r.success && r.data && r.data.length > 0? r.data[0]: null)
				}
			)
		} */

		fetchApiData(
			"finale",
			"fetch_most_recent_PO", filters, null,
			(r) => {
				doneCount++
				console.info('fetch_most_recent_PO', r);
				setPoData(r.success && r.data && r.data.length > 0? r.data[0]: null)
			}
		)
		
		// Get how many item exist per store
		fetchApiData(
			"eio",
			"getInventoryCount", {
				"id_supplier": props.suppliers[selectedSupplier].id_finale,
			}, null,
			(r) => {
				doneCount++
				console.info('getInventoryCount', r);
				setInventoryCount(r.data? r.data : initialState.inventoryCount)
			}
		)

		let timerId = setInterval(() => {
			console.info("Interval", doneCount);
			limit--
			if(doneCount >= awaitingCount || limit == 0){
				props.loading(false)
				clearInterval(timerId)
			}

		}, 200)

	}, [selectedSupplier])

	useEffect(() => {

		if(selectedSupplier === null || (!daysHistory && !dateFrom && !dateTo))	return;

		props.loading(true)
		setPercentIncrease(null)
		/* if(compareToPrevTime){
			fetchSaleReport({
				"id_supplier": props.suppliers[selectedSupplier].id_finale,
				"range": JSON.stringify({
					"duration": "day",
					"length": daysHistory,
					"from": dateFrom? moment(dateFrom).subtract(1, "year").format('YYYY-MM-DD') : null,
					"to": dateTo? moment(dateTo).subtract(1, "year").format('YYYY-MM-DD') : null,
				}),
			}, (r) => {
				console.info('fetchValueInventory_past', r);
				if(r.success){
					setHistoryValueListPastYear(r.data)
					createStoreListData(r.data, (data) => {
						setStoreSalesListPast(data)
					})
					props.loading(false)
				}else{
					props.info({"error": r.error})
				}
			})
		} */
		console.info("dateFromdateFrom", dateFrom);
		console.info("dateTodateTo", dateTo);
		// Load the value for our graph
		fetchSaleReport({
			"id_supplier": props.suppliers[selectedSupplier].id_finale,
			"range": JSON.stringify({
				"duration": "day",
				"length": daysHistory,
				"from": dateFrom? moment(dateFrom).format('YYYY-MM-DD') : null,
				"to": dateTo? moment(dateTo).format('YYYY-MM-DD') : null,
			}),
		}, (r) => {
			console.info('fetchValueInventory', r);
			if(r.success){
				setHistoryValueList(r.data)
				createStoreListData(r.data, (data) => {
					setStoreSalesList(data)
				})
				props.loading(false)
			}else{
				props.info({"error": r.error})
			}
		})

		let saleReport = {
			"duration": "day",
			"offset": daysHistory*2,
			"length": daysHistory
		}
		// if(compareToPrevTime){
			saleReport.from = dateFrom? moment(dateFrom).subtract(1, 'years').format('YYYY-MM-DD') : null
			saleReport.to = dateTo? moment(dateTo).subtract(1, 'years').format('YYYY-MM-DD') : null
		// }

		// Load the value for our history
		fetchSaleReport({
			"id_supplier": props.suppliers[selectedSupplier].id_finale,
			"range": JSON.stringify(saleReport),
		}, (r) => {
			console.info('fetchValueInventory22', r);
			if(r.success){
				setHistoryValueListPastYear(r.data)
				createStoreListData(r.data, (data) => {
					setStoreSalesListPast(data)
				})
			}else{
				props.info({"error": r.error})
			}
		})

		// Load inventory valye graph data 
		loadValueInventory({

		});

		fetchApiData(
			"shipstation",
			"get_db_true_margin", {
				"id_supplier": props.suppliers[selectedSupplier].id_finale,
				"dateFrom": moment().subtract(daysHistory, 'days').format('YYYY-MM-DD'),
			}, null,
			(r) => {
				console.info('get_db_true_margin', r);
				setTrueMarginData(r.data? r.data : initialState.trueMarginData)
			}
		)

	}, [selectedSupplier, daysHistory, compareToPrevTime, dateFrom, dateTo])

	const createStoreListData = (data, callback) => {

		let valuePerStore = {}
		data.forEach((o) => {
			if(!o.customer){
				console.warn("No customer", o)
				return
			}
			// We calculate the total value per store for the total sales line
			if(Object.keys(valuePerStore).indexOf(o.customer) === -1){
				valuePerStore[o.customer] = o.sub_total;
			} else
				valuePerStore[o.customer] += o.sub_total
		})

		// Calculate the percentage of sales
		let total = Object.values(valuePerStore).reduce((a, b) => a + b, 0)

		let stValOrganized = []
		for (const key in valuePerStore) {
			if (Object.hasOwnProperty.call(valuePerStore, key)) {
				stValOrganized.push({
					"storeName": key,
					"total": valuePerStore[key],
					"percent": Math.round((valuePerStore[key] / total) * 100)
				})
			}
		}
		if(callback)	callback(stValOrganized)
	}

	const loadValueInventory = (args, callback) => {

		// Get the date from now to daysHistory in the past
		let dF = moment().subtract(daysHistory, 'days'),
			dT = moment().subtract(1, 'days')

		if(dateFrom && dateTo){
			dF = moment(dateFrom)
			dT = moment(dateTo)
		}

		fetchValueInventory(Object.assign({
			"dateFrom": dF.format('YYYY-MM-DD'),
			"dateTo": dT.format('YYYY-MM-DD'),
			"top": null
		}, args), (r) => {
			console.info("Result fetchValueInventory", r);
			if(r.success){
				let dateList = []
				for (const date in r.res) {
					if (Object.hasOwnProperty.call(r.res, date)) {
						const obj = r.res[date];
						dateList.push({
							"date": date,
							"obj": obj,
						})
					}
				}
				// dateList.reverse()
				setHistoryValueOrderList({
					"data": dateList,
					"vendorList": r.vendorList
				})

				/* let colors = [];
				for (let i = 0; i < r.vendorList.length; i++) {
					colors.push( "#"+((1<<24)*Math.random()|0).toString(16) );
				}
				setColorList(colors) */
			}else{
				props.info({"error": r.error})
			}
		})
	}

	const supplierSection = (pos) => {

		let posInListSupplier = null;
		if(selectedSupplier !== null && selectedSupplier.id_finale)
			props.suppliers.some((o, i) => {
				if(o.id_finale == selectedSupplier.id_finale){
					posInListSupplier = i
					return true
				}
			})

		return <div className="d-flex flex-row align-items-center w-100">
			<div className="input-group w-80">
				<div className="input-group-prepend" style={{width: "20%"}}>
					<span className="input-group-text w-100" id="basic-addon1">Manufacturer</span>
				</div>
				<div style={{width: "55%"}}>
					<Select
						isClearable
						className="w-auto"
						styles={refConst.styleList}
						onChange={(selectedOption) => {
							$('.hasTooltip').tooltip('dispose') // Need to be affective before the rerender of the new form cause nodes will be different.
							store(`selectedSupplier_${pos}`, selectedOption? parseInt(selectedOption.value) : null);
							if(selectedOption){
								setSelectedSupplier(parseInt(selectedOption.value))
								props.selectedSuppliers[props.pos] = parseInt(selectedOption.value)
							}else{
								if(props.pos === 0)
									props.selectedSuppliers[0] = null
								else
									props.selectedSuppliers.splice(props.pos, 1)
							}
							props.setSelectedSuppliers(props.selectedSuppliers.slice(0))
						}}
						options={(() => {
							return props.suppliers.map((o, pos) => {
								return {
									value: pos,
									label: o.name
								}
							})
						})()}
						value={selectedSupplier !== null && props.suppliers[selectedSupplier]? [{
							value: posInListSupplier,
							label: props.suppliers[selectedSupplier].name
						}] : null}
						placeholder={"Supplier"}
						isSearchable={true}
						isMulti={false}
					/>
				</div>
				{
					props.selectedSuppliers.length < 2? <div className="ml-2 bg-dark rounded text-light text-center d-flex align-items-center justify-content-center pointer" style={{width: "40px"}} onClick={(e) => {
						props.selectedSuppliers.push(null)
						props.setSelectedSuppliers(props.selectedSuppliers.slice(0))
					}}><FontAwesomeIcon
							icon={faPlus}
							className=""
						/>
					</div> : ""
				}
				{
					props.pos === 1? <div className="bg-danger rounded text-light text-center d-flex align-items-center justify-content-center pointer ml-2 mr-2" style={{width: "40px"}} onClick={(e) => {
						props.selectedSuppliers.splice(props.pos, 1)
						props.setSelectedSuppliers(props.selectedSuppliers.slice(0))
						store(`selectedSupplier_${props.pos}`, null);
					}}><FontAwesomeIcon
							icon={faTrashAlt}
							className=""
						/>
					</div> : ""
				}
			</div>
			<div>   
				{
					<ModalModifySupplier
						{...props}
						className="btn btn-outline-primary"
						title="Modify"
						selectedSupplier={posInListSupplier}
						idSupplier={selectedSupplier && selectedSupplier.id_finale? selectedSupplier.id_finale : null}
						suppliers={props.suppliers}
					/>
				}
			</div>
		</div>
	}

	const filterTrueMarginData = () => {
		if(trueMarginData === null)	return []

		let list =  trueMarginData.filter((o) => {
			if(!selectedStoreName || selectedStoreName == o.store_name){
				return o
			}
		})

		// Sort per state
		list.sort((obj1, obj2) => {
			if(obj1.marginPrc > obj2.marginPrc)
				return 1
			return -1
		})
		return list
	}

	const getMarginPerStateList = (pos) => {
		if(trueMarginData === null)	return []
		let listPerState = [],
			statePos = []
		filterTrueMarginData().forEach((o) => {

			if(statePos.indexOf(o.state) === -1){
				statePos.push(o.state)
				listPerState.push({
					"state": o.state,
					"list": []
				})
			}
			let pos = statePos.indexOf(o.state)
			listPerState[pos].list.push(o.marginPrc)
		})
		console.info("listPerState2", listPerState);
		// Average the margin per state
		listPerState.forEach((listPrc, pos) => {
			listPerState[pos].avg = Math.round(listPrc.list.reduce((a, b) => a + b, 0) / listPrc.list.length)
		});
		// Sort per state
		listPerState.sort((obj1, obj2) => {
			if(obj1.avg > obj2.avg)
				return 1
			return -1
		})
		console.info("listPerState", listPerState);
		return listPerState
	}

	const generalTrueMarginAvg = (pos) => {
		if(trueMarginData === null || trueMarginData.length === 0)	return 0
		let list = []
		filterTrueMarginData().forEach((o) => {
			list.push(o.marginPrc)
		})
		if(list.length === 0)	return 0 
		return Math.round(list.reduce((a, b) => a + b, 0) / list.length)
	}

	const supplierDataSection = (pos) => {

		if(props.suppliers.length === 0)	return ""

		let supplier = props.suppliers[selectedSupplier] ?? null
		let storeNamePerId = []
		refConst.stores.forEach((o) => {
			storeNamePerId[o.id] = o.name
		})
		let listCountFinale = inventoryCount? inventoryCount.filter(o => o.idStore == 5) : []
		let totalSkuCount = listCountFinale.length > 0? listCountFinale[0].count : 0
		let colorStatus = "bg-info"

		if(poData){
			let dateLastPo = moment(poData.last_po_date)
			let diff = moment().diff(dateLastPo, 'days');
			console.info("diff", diff);
			if(diff <= 7)
				colorStatus = "bg-success"
			else if(diff <= 14)
				colorStatus = "bg-warning"
			else if(diff > 30)
				colorStatus = "bg-danger"
		}

		let modalMarginDetails = (refresh) => {
			props.modal({
				show: refresh? false : true,
				title: <span>True Margin Details</span>,
				html: (popup) => {
	
					return <div className="text-center">
						<div style={{
							maxHeight: "300px",
							overflowY: "auto"
						}}>
							<table className="table table-sm table-bordered middeSizeText">
								<thead>
									<tr>
										<th>Order ID</th>
										<th>Shipping</th>
										<th>Tracking</th>
										<th>Store</th>
										<th>State</th>
										<th>Vendor</th>
										<th>Cost</th>
										<th>Order Total</th>
										<th>Shipping Cost</th>
										<th>Tax</th>
										<th>Margin Value</th>
										<th>Margin %</th>
									</tr>
								</thead>
								<tbody>
									{
										filterTrueMarginData().map(o => {
											return <tr key={o.order_id}>
												<td>{o.order_id}</td>
												<td>{o.shipping_method}</td>
												<td>{o.tracking_number}</td>
												<td>{o.store_name}</td>
												<td>{o.state}</td>
												<td>{o.vendor}</td>
												<td>{o.finale_current_cost}</td>
												<td>{o.shipment_cost}</td>
												<td>{o.tax_amount}</td>
												<td>{o.order_total}</td>
												<td>${Math.round(o.marginValue)}</td>
												<td>{Math.round(o.marginPrc)}%</td>
											</tr>
										})
									}
								</tbody>
							</table>
						</div>
					</div>
				}
				, exit: (popup, close) => {
					close()
				}
				, ok: null, return: {
					title: "Exit",
					fnc: (popup, close) => {
						close()
					}
				}
			});
		}

		let keyToNameRewrite = {
			"free_freight_threshold": "Free Freight",
			"min_order_value": "Minimum",
			"account": "Account",
			"po_id": "PO ID",
			"phone_1": "Phone 1#",
			"phone_2": "Phone 2#",
			"contact_name": "Contact",
		}

		let keyToShorterStoreName = {
			"Amazon - Gott Electronics": "Amazon - Gott",
			"Amazon - Gott Electronics-CA": "Amazon - Gott CA",
			"EIO.com": "EIO",
		}

		let totalSales = parseInt(storeSalesList.reduce((a, b) => a + b.total, 0))

		return <div className={(props.doubled? "smallView " : "") + "menufacturerSection w-100 border rounded m-3"} style={{backgroundColor: "rgb(238 238 238)"}}>
			<div className="pr-3 pl-3 pt-2 pb-2">{ supplierSection(pos) }</div>
			{ supplier? <div className="row no-gutters">
				<div className="col-9"/*  style={{backgroundColor: "red"}} */>
					{/* LEFT */}
					<div className="row justify-content-around no-gutters pt-2">
						<div className="col-4 border rounded bg-white" style={{
							minHeight: "80px",
							backgroundImage: supplierInfo? "url(" + supplierInfo.img_url + ")" : "",
							backgroundPosition: "center center",
							backgroundRepeat: "no-repeat",
							backgroundSize: "contain"
						}}>
							{/* <img src={supplierInfo? supplierInfo.img_url : ""} alt="logo" className="w-100" /> */}
						</div>
						<div className="col-5 border rounded bg-white d-flex flex-column justify-content-around pl-2 pr-2">
							<div><span className="mr-3">Open PO Value:</span>{poData? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(poData.total_value) : ""}</div>
							<hr className="m-0 p-0"/>
							<div>
								<span className="mr-3">Most recent PO:</span> 
								{poData? poData.last_po_date : ""}
							</div>
							<hr className="m-0 p-0"/>
							<div>
								<span className="mr-3">POID:</span>
								{
									poData? <a target="_blank" rel="noopener noreferrer" href={`https://app.finaleinventory.com/eio/sc2/?order/purchase/order/` + btoa(`/eio/api/order/` + (poData? poData.last_po_id : ""))}>
										{poData? poData.last_po_id : ""}
									</a> : ""
								}
								</div>
						</div>
						<div className="col-2 border rounded bg-white" style={{minHeight: "80px"}}>
							<div className="text-center">Status</div>
							<div style={{
								borderRadius: "100px",
								height: "50px",
								width: "50px",
							}} className={"m-auto " + colorStatus}>

							</div>
						</div>
					</div>
					<div className="p-2">
						<table className="w-100 m-0 p-0 border rounded bg-white tblSkuCount">
							<tbody>
								<tr>
									<td className="p-0 text-center border-bottom">
										<div className="text-wrap">SKU Count</div>
									</td>
									{
										inventoryCount? inventoryCount.map((o, i) => {
											if(o.idStore != refConst.id_store_finale)
												return <td key={i} className="p-0 text-center border-bottom border-left">
													<div className="text-wrap">{keyToShorterStoreName[storeNamePerId[o.idStore]] ?? storeNamePerId[o.idStore]}</div>
												</td>
										}) : ""
									}
								</tr>
								<tr>
									<td className="p-0 text-center">
										<div>
											{ totalSkuCount }
										</div>
									</td>
									{
										inventoryCount? inventoryCount.map((o, i) => {
											if(o.idStore != refConst.id_store_finale)
												return <td key={i} className="p-0 text-center" title={o.count}>
													<div className="">{Math.round(100 / (totalSkuCount / o.count))}%</div>
												</td>
										}) : ""
									}
								</tr>
							</tbody>
						</table>
					</div>
					<div className="">
						<div className="d-flex flex-column align-items-stretch ml-2 mr-2 no-gutters text-wrap text-break border rounded bg-white">
							<div className="d-flex flex-row">
								<div className="col-3 p-2 d-flex align-items-center justify-content-center">
									Total Sales per Filter:
								</div>
								<div className="col-2 p-2 middeSizeText d-flex align-items-center justify-content-center" style={{
									backgroundColor: "#EEE",
									boxShadow: "inset 0px 0px 8px #CCC"
								}}>
									{
										new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
											storeSalesList.filter(s => {
												if(selectedStoreName && selectedStoreName != s.storeName)	return false;
												return true;
											}).reduce((a, b) => a + b.total, 0)
										)
									}
								</div>
								<div className="col-3 p-2 text-break d-flex align-items-center justify-content-center">
									Percent Growth to Prior Period
								</div>
								<div className={(percentIncrease > 0? "text-success" : "text-danger") + " col-2 p-2 text-center d-flex align-items-center justify-content-center"}>
									{percentIncrease === null?
										<Loading centered={true} loading={true} className="h-100 w-100" style={{height: "20px", transform: "scale(1.5)"}}/> 
										:
										(percentIncrease > 0? "+" : "")+`${percentIncrease}%`}
								</div>
								<div className="col-2 p-2 d-flex align-items-center justify-content-center">
									<u><a className="text-primary pointer" style={{/* fontSize: "14px" */}} onClick={() => {
										setDaysHistory(30)
										setSelectedStoreName(null)
										setDateFrom(null)
										setDateTo(null)
										setCompareToPrevTime(true)
									}}>Reset Filter</a></u>
								</div>
							</div>
							<table className="w-100 m-0 p-0 border rounded border-top bg-white tblSkuCount">
								<tbody>
									<tr>
										{/* <td className="p-0 text-center border-bottom">
											<div className="text-wrap">Percent Sales</div>
										</td> */}
										{
											storeSalesList? storeSalesList.map((o, i) => {
												return <td key={i} className="p-0 text-center border-bottom">
													<div className="text-wrap">{o.storeName}</div>
												</td>
											}) : ""
										}
									</tr>
									<tr>
										{/* <td className="p-0 text-center">
											<div>
												{ new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalSales) }
											</div>
										</td> */}
										{
											storeSalesList? storeSalesList.map((o, i) => {
												if(o.idStore != refConst.id_store_finale)
													return <td key={i} className="p-0 text-center" title={o.count}>
														<div className="">{Math.round((o.total / totalSales) * 100)}%</div>
													</td>
											}) : ""
										}
									</tr>
								</tbody>
							</table>
						</div>
						<div className="d-flex align-items-stretch ml-2 mr-2 no-gutters text-wrap text-break border rounded bg-white mt-2">
							<div className="col-3 p-2 d-flex align-items-center justify-content-center">
								True Margin:
							</div>
							<div className={" col-7 p-2 text-left d-flex"}>
								{
									trueMarginData === null?
										<Loading centered={true} loading={true} className="h-100 w-100" style={{height: "20px", transform: "scale(1.5)"}}/> 
									:
										<div className="d-flex flex-row align-items-center">
											<span className="mr-3">{ generalTrueMarginAvg() }%</span>
											<select className="custom-select custom-select-sm" style={{
												width: "200px",
											}}>
												{
													getMarginPerStateList(pos).map((o, i) => {
														return <option key={i}>{o.state}: {o.avg}%</option>
													})
												}
											</select>
										</div>
								}
							</div>
							<div className="col-2 p-2 d-flex align-items-center justify-content-center">
								<u><a className="text-primary pointer" style={{/* fontSize: "14px" */}} onClick={() => {
									modalMarginDetails()
								}}>See details</a></u>
							</div>
						</div>
						{
							props.doubled && props.inlineView? 
							<div>
								<div className="m-2 text-wrap">
									{
										storeSalesList.map((s, i) => {
											return <button key={i} className={"btn btn-outline-dark btn-sm mb-2 mr-1 ml-1" + (selectedStoreName == s.storeName? " active" : "")} onClick={() => {
												setSelectedStoreName(s.storeName)
											}}>{keyToShorterStoreName[s.storeName] ?? s.storeName}</button>
										})
									}
								</div>
								<div className="ml-2 mr-2 mb-2 text-wrap">
									{
										[30, 60, 90, 180, 365].map((days, i) => {
											return <button key={i} className={"btn btn-outline-primary btn-sm mb-2 mr-1 ml-1" + (daysHistory == days? " active" : "")} onClick={() => {
												setDaysHistory(days)
											}}>{days}</button>
										})
									}
								</div>
							</div> : ""
						}
					</div>
					{/* {
						!props.doubled && chartData? <Line options={chartOption} data={chartData} /> : ""
					} */}
					{
						(!props.doubled || !props.inlineView) && (!dateFrom && !dateTo)?  <canvas id={`graphHistoryInventoryValue_${props.pos}`} className="w-100 mt-2"></canvas> : ""
					}
					{
						(!props.doubled || !props.inlineView) && (dateFrom && dateTo)?  <canvas id={`graphHistoryInventoryValueSelect_${props.pos}`} className="w-100 mt-2"></canvas> : ""
					}
				</div>
				<div className="col-3"/*  style={{backgroundColor: "blue"}} */>
					{/* RIGHT */}
					<div className="border rounded m-2 bg-white">
						<div className="text-center">Details</div>
						<table className="w-100">
							<tbody>
								{
									[
										"free_freight_threshold",
										"min_order_value",
										"account",
										"po_id",

										"phone_1",
										"phone_2",
										"contact_name",
									].map((key, i) => {
										return <tr key={i}>
											<td className="border-top border-right p-1 pl-2 w-50">{keyToNameRewrite[key] ?? key}</td>
											<td className="border-top p-1 pr-2 text-right w-50 text-wrap">{supplier? supplier[key] : ""}</td>
										</tr>
									})
								}
								<tr>
									<td className="border-top border-right p-1 pl-2 w-50">LINK</td>
									<td className="border-top p-1 pr-2 text-right w-50 text-wrap">
										{
											poData? <a target="_blank" rel="noopener noreferrer" href={`https://app.finaleinventory.com/eio/sc2/?order/purchase/order/` + btoa(`/eio/api/order/` + (poData? poData.last_po_id : ""))}>
												Open in Finale
											</a> : ""
										}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					{
						!props.doubled || !props.inlineView? 
							<div>
								{/* History btn for last 30, 60, 90, 180, 365 days */}
								<div className="border rounded m-2 bg-white">
									<div className="text-center">Stores</div>
									<div className="d-flex justify-content-around flex-wrap p-2">
										{
											storeSalesList.map((s, i) => {
												return <button key={i} className={"btn btn-outline-primary btn-sm mb-2 mr-1 ml-1" + (selectedStoreName == s.storeName? " active" : "")} onClick={() => {
													setSelectedStoreName(s.storeName)
												}}>{keyToShorterStoreName[s.storeName] ?? s.storeName}</button>
											})
										}
										{
											selectedStoreName?	<button className={"btn btn-outline-dark btn-sm smallText active"} onClick={() => {
												setSelectedStoreName(null)
											}}>Reset</button> : ""
										}
									</div>
								</div>
								<div className="border rounded m-2 bg-white">
									<div className="text-center">History</div>
									<div className="d-flex justify-content-around flex-wrap p-2">
										{
											[30, 60, 90, 180, 365].map((days, i) => {
												return <button key={i} className={"btn btn-outline-primary btn-sm mb-2 mr-1 ml-1" + (daysHistory == days? " active" : "")} onClick={() => {
													setDateFrom(null)
													setDateTo(null)
													setDaysHistory(days)
													setStoreSalesList([])
													setHistoryValueList([])
												}}>{days}</button>
											})
										}
										<span className="smallText text-wrap text-center">
											{
												dateFrom && dateTo? "You have currenctly selected a date range which overstrep this setting for the graphs.": ""
											}
										</span>
									</div>
								</div>
								<div className="border rounded m-2 bg-white">
									<div className="text-center">Select graphs date range</div>
									<div className="d-flex justify-content-around flex-wrap p-2">
										<ManualDateRangeModal
											{...props}
											name="Select date range"
											dateFrom={dateFrom}
											dateTo={dateTo}
											setDateFrom={setDateFrom}
											setDateTo={setDateTo}
											setCompareToPrevTime={setCompareToPrevTime}
											compareToPrevTime={compareToPrevTime}
											className={dateFrom && dateTo? "active" : ""}
										/>
										{
											dateFrom && dateTo?	<button className={"btn btn-outline-dark btn-sm smallText" + (dateFrom && dateTo? " active" : "")} onClick={() => {
												setDateFrom(null)
												setDateTo(null)
												setCompareToPrevTime(true)
												setStoreSalesList([])
												setHistoryValueList([])
											}}>Reset</button> : ""
										}
									</div>
								</div>
							</div> : ""
					}
				</div>
			</div> : ""}
			{
				props.doubled && props.inlineView && (!dateFrom && !dateTo)?  <canvas id={`graphHistoryInventoryValue_${props.pos}`} className="w-100 mt-2"></canvas> : ""
			}
			{
				props.doubled && props.inlineView && (dateFrom && dateTo)?  <canvas id={`graphHistoryInventoryValueSelect_${props.pos}`} className="w-100 mt-2"></canvas> : ""
			}
			<div className="mt-2">
				<div className="d-flex align-items-center pl-3 pr-3 w-100">
					<span className="">Daily Inventory Value</span>
					<Select
						isClearable
						className="ml-3"
						styles={styleList}
						onChange={(selectedOption) => {
							console.info("selectedOption", selectedOption);

							let listSupIn = selectedOption.map(o => o.label)
							suppliersSelectedValue.forEach((supplier, i) => {
								if(listSupIn.indexOf(supplier) === -1 && supplier != props.suppliers[selectedSupplier].name){
									suppliersSelectedValue.splice(i, 1)
								}
							})
							selectedOption.forEach((s, i) => {
								if(suppliersSelectedValue.indexOf(s.label) === -1){
									suppliersSelectedValue.push(s.label)
								}
							})

							setSuppliersSelectedValue([...suppliersSelectedValue])
						}}
						options={(() => {
							return historyValueOrderList && Array.isArray(historyValueOrderList.vendorList)? historyValueOrderList.vendorList.map((name, pos) => {
								return {
									value: pos,
									label: name
								}
							}) : []
						})()}
						/* value={selectedSupplier !== null? [{
							value: selectedSupplier,
							label: props.suppliers[selectedSupplier].name
						}] : null} */
						placeholder={"Suppliers"}
						isSearchable={true}
						isMulti={true}
					/>
				</div>
				{
					<canvas id={`graphHistoryInventoryOrderValue_${props.pos}`} className="w-100"></canvas>
				}
			</div>
		</div>
	}
	
	if(!props.securityClearance(props))	return "";

	console.info("supplierssuppliers", props.suppliers);
	console.info("inventoryCount", inventoryCount);
	console.info("historyValueList", historyValueList);
	console.info("storeSalesList", storeSalesList);
	console.info("selectedSupplier", selectedSupplier);
	console.info("chartDatachartData", chartData);
	console.info("inlineViewChart", props.doubled && props.inlineView? 3 : 4);
	console.info("dateFrom", dateFrom);
	console.info("dateFrom", moment(dateFrom).format('YYYY-MM-DD'));
	console.info("dateTo", moment(dateTo).format('YYYY-MM-DD'));

	return (supplierDataSection(props.pos));
}