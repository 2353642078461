// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import { getSupplier } from '../../../fnc'
import {
	NavLink
} from 'react-router-dom';
import store from 'store2';
import Select from 'react-select';
import loading from '../../../Rolling.svg';
import SupplierBox from './SupplierInfo.js';
import QuickViewPoStatus from './QuickViewPoStatus.js';
import QuickDlReport from './QuickDlReport.js';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@fortawesome/free-solid-svg-icons'
const refConst = require("../../../constants.js").v

var initialState = {
	_isMounted: false,
	selectedSuppliers: [
		/* {
			id: 1,
			name: "Supplier 1",
		} */
	],
	suppliers: [],
	supplierInfo: null,
	poData: null,
	inventoryCount: null,
	daysHistory: null,
	selectedStoreName: null,
	colorPerStoreId: [],
	percentIncrease: null,
	inlineView: true,
}

export default function ManufacturerMain(props){

	const [suppliers, setSuppliers] = useState(initialState.suppliers);
	const [selectedSuppliers, setSelectedSuppliers] = useState([null]);
	const [supplierInfo, setSupplierInfo] = useState(initialState.supplierInfo);
	const [poData, setPoData] = useState(initialState.poData);
	const [inventoryCount, setInventoryCount] = useState(initialState.inventoryCount);
	const [inlineView, setInlineView] = useState(initialState.inlineView);

	useEffect(() => {
		$('.tooltipInfo').tooltip({
			placement: "right",
			template:  '<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner tooltip-info-ebay"></div></div>',
		}).tooltip('update')

		$('.tooltipInfo').on('click', function() {
			$('.tooltip').tooltip('hide');
		});
	})
	
	useEffect(() => {
		console.info('refConst', refConst);
		
		initialState._isMounted = true;
		
		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {
		
		init()

	}, [])

	const init = () => {

		getSupplier({}, (r) => {
			console.info('getSupplier', r);
			props.loading(false)
			if(r.data)
				r.data.sort((obj1, obj2) => {
					if(obj1.name > obj2.name)
						return 1
					return -1
				})
			setSuppliers(r.success? r.data : [])
		})
	}

	const specialSection = () => {

		return <div className="w-100" style={{/* maxWidth: "200px" */ paddingBottom: "120px"}}>
			<div className="row">
				<div className="col">
					<div className="">
						<QuickViewPoStatus
							{...props}
							suppliers={suppliers}
						/>
						<QuickDlReport
							{...props}
						/>
						{/* <div className="">
							Left
						</div>
						<div className="">
							Right
						</div> */}
					</div>
				</div>
			</div>
			{
				selectedSuppliers.length === 2?<div className="d-flex flex-row-reverse">
					<button className={(inlineView? "active " : "") + "btn btn-outline-dark tooltipInfo"} onClick={() => {
						setInlineView(!inlineView)
					}} data-html="true" title="Show the 2 supplier data on the same line">
						<FontAwesomeIcon
							icon={faList}
							className=""
						/>
					</button>
				</div> : ""
			}
			<div className={(inlineView? "" : "d-flex flex-column ") + "row mr-2"}>
				{
					selectedSuppliers.filter(o => {
						return true
					}).map((o, i) => {
						return <div key={i} className={(inlineView? "w-50 " : "" ) + "col"}>
							<SupplierBox
								suppliers={suppliers}
								pos={i}
								doubled={selectedSuppliers.length > 1}
								setSelectedSuppliers={setSelectedSuppliers}
								selectedSuppliers={selectedSuppliers}
								inlineView={inlineView}
								{...props}
							/>
						</div>
					})
				}
			</div>
		</div>
	}
	
	if(!props.securityClearance(props))	return "";

	return (
		<div className="main ctnPage d-flex flex-row flex-wrap">
			{ specialSection() }
		</div>
	);
}