// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import {getSupplier} from "../../../fnc.js"

const refConst = require("../../../constants.js").v

var initialState = {
	supplierInfo: null,
}

function fnc(props) {

	const [supplierInfo, setSupplierInfo] = useState(initialState.supplierInfo);

	useEffect(() => {

		props.setRefresher(Math.random())

	}, [supplierInfo])

	useEffect(() => {
		if(!props.item || !props.item.id_supplier1)
			setSupplierInfo(initialState.supplierInfo)
		else{

			getSupplier({
				"id": props.item.id_supplier1,
			}, (r) => {
				console.info('Fetched_supplierInfo:', r);
				setSupplierInfo(r.success && r.data && r.data.length > 0? r.data[0] : initialState.supplierInfo)
			})
		}
		return () => {
			setSupplierInfo(initialState.supplierInfo)
		}
		
	}, [props.item])

	return (props.sectionBuilder("Supplier Info", () => {

		let listInfo = [];
		if(supplierInfo){

			let keyToNameRewrite = {
				"free_freight_threshold": "Free Freight",
				"min_order_value": "Minimum",
				"account": "Account",
				"po_id": "PO ID",
				"phone_1": "Phone 1#",
				"phone_2": "Phone 2#",
				"contact_name": "Contact",
			}
			for (const key in supplierInfo) {
				if (Object.hasOwnProperty.call(supplierInfo, key)) {
					const val = supplierInfo[key];
					if(keyToNameRewrite[key]){
						listInfo.push(<tr key={`supplierInfo_${key}`} className="d-flex justify-content-between border-bottom">
							<td className="m-0 p-1 smallView">{keyToNameRewrite[key]}</td>
							<td className="m-0 p-1 smallView">{val}</td>
						</tr>)
					}
				}
			}
		}

		return listInfo.length > 0? <div>
			<table className="w-100">
				<tbody>
					{listInfo}
				</tbody>
			</table>
		</div> : "N/A"
	}, {
		minWidth: "280px"
	}));
}

export default fnc