// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import {fetchPriceHistory} from "../../../fnc.js"
import logoStoreEio from '../../../img/fav-trans-eio-border.png';
import logoStoreHaus from '../../../img/fav-trans-hausOfTools.png';
import logoStoreEbay from '../../../img/ebay_circle_perso.png';
import logoStoreWalmart from '../../../img/walmart_circle_updated.png';
import logoStoreAmazon from '../../../img/amazon_circle_perso.png';
import logoStoreAmazonGott from '../../../img/amazon_circle_perso_gott_v2.png';

const refConst = require("../../../constants.js").v

var initialState = {
}

function fnc(props) {
	
	return (props.sectionBuilder("Quantities & Prices", () => {

		let eioStore = refConst.stores.find(s => {
			if(s.id === refConst.id_store_eio)	return s
		})
		let hausStore = refConst.stores.find(s => {
			if(s.id === refConst.id_store_hausfTools)	return s
		})
		let eBayEio = refConst.stores.find(s => {
			if(s.id === refConst.id_store_eBay_EIO)	return s
		})
		let walmart = refConst.stores.find(s => {
			if(s.id === refConst.id_store_walmart_eio)	return s
		})
		let amazon = refConst.stores.find(s => {
			if([refConst.id_store_amazon_GOTT, refConst.id_store_amazon_EIO].indexOf(s.id) !== false)	return s
		})

		let urlOptions = [];

		if(props.item.eio_id){
			urlOptions.push({
				value: "https://" + eioStore.account_name + "/admin/products/" + props.item.eio_id,
				label: eioStore.name + " (Admin)"
			})
			urlOptions.push({
				value: "https://" + eioStore.account_name + "/products/" + props.item.eio_handle,
				label: eioStore.name
			})
		}
		if(props.item.haus_id){
			urlOptions.push({
				value: "https://" + hausStore.account_name + "/admin/products/" + props.item.haus_id,
				label: hausStore.name + " (Admin)"
			})
			urlOptions.push({
				value: "https://" + hausStore.account_name + "/products/" + props.item.haus_handle,
				label: hausStore.name
			})
		}
		if(props.item.ebay_id){
			urlOptions.push({
				// https://www.ebay.com/sl/list?itemId=255523718975&mode=ReviseItem
				value: "http://www.ebay.com/sl/list?itemId=" + props.item.ebay_id+"&mode=ReviseItem",
				label: eBayEio.name + " (Admin)"
			})
			urlOptions.push({
				value: props.item.ebay_url,
				label: eBayEio.name
			})
		}

		if(props.item.url_link)
			urlOptions.push({
				value: `https://app.finaleinventory.com/eio/sc2/?product/detail/` + btoa(props.item.url_link),
				label: `Finale`
			})

		if(props.item.amazon_us_eio_asin || props.item.amazon_us_gott_asin){
			let asin = props.item.amazon_us_eio_asin || props.item.amazon_us_gott_asin;
			urlOptions.push({
				value: `https://sellercentral.amazon.com/abis/listing/edit/offer?marketplaceID=ATVPDKIKX0DER&ref=xx_myiedit_cont_myifba&sku=${encodeURIComponent(props.item.sku)}&&asin=${asin}`,
				label: `${amazon.name} (Admin)`
			})
			urlOptions.push({
				value: `https://www.amazon.com/dp/${asin}?ref=myi_title_dp`,
				label: `${amazon.name} Store`
			})
		}

		if(props.item.walmart_id)	urlOptions.push({
			value: props.item.walmart_url,
			label: walmart.name
		})

		return <Select
			styles={refConst.styleList}
			className="mt-2"
			onChange={(selectedOption) => {
				window.open(selectedOption.value,'_blank');
			}}
			options={urlOptions}
			isSearchable={true}
			isMulti={false}
			placeholder={"Select to open link in a new tab"}
		/>
	}, {
		minWidth: "280px"
	}));
}

export default fnc