import $ from 'jquery'; 
import moment from 'moment';
import React, {  } from 'react'
const refConst = require("./constants.js").v

const parseAjaxReturn = function(objReturned){
	// {"updated":0,"processed":0,"ignored":50,"error":0,"totalRows":50,"duplicated":0,"ids":[]}
	let returnHtml = ``;

	for (const key in objReturned) {
		if (objReturned.hasOwnProperty(key) && ['ids', 'id', 'ok'].indexOf(key) === -1) {
			const val = objReturned[key];
			let valIsBool = val===true || val===false? true : false;

			if(val > 0)
				returnHtml += valIsBool? `<div>${key}</div>` : `<div>${key}: ${val}</div>`;
		}
	}

	return returnHtml;
}

export {parseAjaxReturn};

const roundPrice = function(value, decimals){
	if(!decimals)	decimals = 2
	return Number(Math.floor(parseFloat(value + 'e' + decimals)) + 'e-' + decimals);
}
export {roundPrice};


const meta = {
	fetch: (o, callback) => {

		ajaxPerso( {
			"api": "shopify",
			"trigger": "fetch_meta",
			"shop": o.shop,
			"urlPrefix": o.urlPrefix? o.urlPrefix : "",
			"namespace": o.namespace,
			"key": o.key
		}, callback)
	},
	create: (o, callback) => {
		console.info('Will create this:', o.value);

		if("v" in o && o.v)
			console.info('META', {
				"api": "shopify",
				"trigger": "createUpdate_meta",
				"shop": o.shop,
				"urlPrefix": "urlPrefix" in o && o.urlPrefix? o.urlPrefix : "",
				"data": JSON.stringify({
					namespace: o.namespace,
					key: o.key,
					value: JSON.stringify(o.value),
					type: "json"
				}),
			});
		else
			ajaxPerso( {
				"api": "shopify",
				"trigger": "createUpdate_meta",
				"shop": o.shop,
				"urlPrefix": "urlPrefix" in o && o.urlPrefix? o.urlPrefix : "",
				"data": JSON.stringify({
					namespace: o.namespace,
					key: o.key,
					value: JSON.stringify(o.value),
					type: "json"
				}),
			}, callback)
	},
	delete: (o, callback) => {

		ajaxPerso( {
			"api": "shopify",
			"trigger": "del_meta",
			"shop": o.shop,
			"id": o.id,
			"verbose": false
		}, callback)
	}
}

export {meta};

var handelize = (function() {
		
	var mapping = {};

	var fromRemove = "'",
		toRemove = "";
	for(var i1 = 0, j1 = fromRemove.length; i1 < j1; i1++ )
		//mapping[ from.charAt( i ) ] = to.charAt( i );
		mapping[ fromRemove.charAt( i1 ) ] = toRemove;

	var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç{}´-+¿?.,;:[]*¨¡!=()&%$#/\"_",
		//to   = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc                         ",
		to = " ";

	for(var i = 0, j = from.length; i < j; i++ )
		//mapping[ from.charAt( i ) ] = to.charAt( i );
		mapping[ from.charAt( i ) ] = to;
	return function( str ) {
		if(!str || str.length === 0)	return '';
		var ret = [];
		for( var i = 0, j = str.length; i < j; i++ ) {
			var c = str.charAt( i );
			if( mapping.hasOwnProperty( str.charAt( i ) ) )
				ret.push( mapping[ c ] );
			else
				ret.push( c );
		}
		//return ret.join( '' );
		return ret.join( '' ).trim().replace( /[^-A-Za-z0-9®]+/g, '-' ).toLowerCase();
	};
})();

export {handelize};

const ajaxPerso = function(args, callback, failCallback, addArgs, forceCaptcha){
	// console.info('forceCaptcha', forceCaptcha);
	
	if((!refConst.byPassRecaptcha || forceCaptcha) && !("special" in args)){
	// if(window.grecaptcha && !refConst.byPassRecaptcha || forceCaptcha){

		if(window.grecaptcha && window.grecaptcha.enterprise){
			window.grecaptcha.enterprise.ready(function() {
			// window.grecaptcha.ready(function() {
				let actionName = "recapForceName" in args? args.recapForceName : `${args.api}/${args.trigger/* .replaceAll('_', '/').replaceAll('-', '/') */}`;
				console.info('actionName', actionName);
				
				// window.grecaptcha.execute("6LdMEKoaAAAAADQDFdOk3F3HC8F9ZjT7eXe6iGiq", {action: actionName}).then(function(token) {
				window.grecaptcha.enterprise.execute("6Ld5cM4dAAAAAIR103Fx78hXh9Omssru3RTzABs0"/* "6LdMEKoaAAAAADQDFdOk3F3HC8F9ZjT7eXe6iGiq" */, {action: actionName}).then(function(token) {
					
					// Add your logic to submit to your backend server here.
					// console.info('token', token);
					// console.info("appendappend", "append" in args? true : false);
	
					if("append" in args)
						args.append("token", token);
					else
						args.token = token;
	
					// console.info('args', args);
					ajaxPost(args, callback, failCallback, addArgs);
				});
			});
		}
	}else{
		args.token = refConst.tokenEio;
		return ajaxPost(args, callback, failCallback, addArgs);
	}
}

export {ajaxPerso};

const clipboard = function(value, props){

	navigator.clipboard.writeText(value).then(function() {
		props.info({success: `[${value}] Copied in your Clipboard`, second: 2})
	}, function() {
		props.info({error: `Copy to clipboard failled.`, second: 2})
	});
}

export {clipboard};

function uniqueArray(a) {
	return [...new Set(a)];
}
export {uniqueArray};

const socketPerso = function(trigger, object, callback){
	let limitTry = 100,
		count = 0

	var intSocketPerso = setInterval(() => {
		if(count > limitTry || "socket" in window){
			clearInterval(intSocketPerso);

			/* if(window.grecaptcha && window.grecaptcha.enterprise){
				window.grecaptcha.enterprise.ready(function() {
					let actionName = object && "internalReq" in object? `${object.internalReq.api}_${object.internalReq.trigger}` : trigger;
					
					window.grecaptcha.enterprise.execute("6Ld5cM4dAAAAAIR103Fx78hXh9Omssru3RTzABs0", {action: actionName}).then(function(token) {
						object.token = token;
						console.info('Send socket', object)						 */
						window.socket.emit(trigger, object, callback)
					/* });
				});
			} */
		}
	}, 20);
}

export {socketPerso};

const getProductTypeList = (idShop, callback) => {
		
	ajaxPerso( {
		"api": "shopify",
		"trigger": "getProductTypeList",
		"shop": idShop
	}, callback);
}
export {getProductTypeList};


const ajaxPost = function(args, callback, failCallback, addArgs){
	if(!addArgs || typeof addArgs != 'object') addArgs = {};
	
	try {
		return $.ajax(Object.assign({
			url: refConst.urlServer,
			type: "POST",
			data: args,
			// timeout: 600000, // Timeout in milliseconds
			// dataType: "auto",
		}, addArgs)).done(callback).fail(failCallback? failCallback : function(jqXHR, textStatus, errorThrown) {
			console.warn("REQ ERROR: ", textStatus);
			let valOut = textStatus
			if(textStatus.indexOf("parsererror") !== -1)
				valOut = "Please verify your response, the JSON received wasn't correct."
			alert(valOut);
		});
		/* $.post(refConst.urlServer, args, callback, "json").fail(failCallback? failCallback : function(jqXHR, textStatus, errorThrown) {
			alert( "Error:" );
			console.warn( textStatus );
		}); */
	} catch (error) {
		if(failCallback){
			failCallback(error);
		}else{
			console.warn("REQ ERROR: ", error);
			let valOut = error
			if(typeof error === 'string' && error.indexOf("parsererror") !== -1)
				valOut = "Please verify your response, the JSON received wasn't correct."
			alert(valOut);
		}
	}
}


const shopify_promo_object = function(idShop, idProduct, idVariant, price, promoMetaObj){

	if(!idVariant || !price)
		throw("Please fill the idVariant and price.")

	let oldPrice = parseFloat(price)
	//, newPrice = parseFloat(price) - (promoMetaObj.value_type === 'percentage'? price * parseFloat(promoMetaObj.value) / 100 : parseFloat(promoMetaObj.value))

	let objOut = {
		"id_marketplace": idShop,
		"id_product_shopify": idProduct,
		"id_variant_shopify": idVariant,
		"change_type": promoMetaObj.value_type,
		"change_value": promoMetaObj.value,
		"original_price": oldPrice,
		// "new_price": newPrice,
		"starting_date": moment.unix(promoMetaObj.startingDate).format('YYYY-MM-DD HH:mm:ss'),
		"ending_date": moment.unix(promoMetaObj.endingDate).format('YYYY-MM-DD HH:mm:ss')
	}

	if(promoMetaObj.tempOriginalPrice > 0.0)
		objOut.temp_original_price = promoMetaObj.tempOriginalPrice

	return objOut;
}

export {shopify_promo_object};

const getCaptchaToken = function(args, callback){
	if(window.grecaptcha && !refConst.dev)
		window.grecaptcha.ready(function() {
			let actionName = "recapForceName" in args? args.recapForceName : `${args.api}__${args.trigger}`;
			window.grecaptcha.execute("6LdMEKoaAAAAADQDFdOk3F3HC8F9ZjT7eXe6iGiq", {action: actionName}).then(function(token) {
				if(callback)	callback(token)
			});
		});
}

export {getCaptchaToken};

const fetchMenu = (args, loadingFnc, callback) => {
		
	if(!args)	throw("fetchMenu Please indicate a store ID");
	if(!("shop" in args) || !args.shop)	throw("fetchMenu Please indicate a store ID");
	if(loadingFnc)	loadingFnc()
	
	ajaxPerso(Object.assign({
		"api": "eio",
		"trigger": "getMenu",
		// "shopify": true
	}, args), callback);
}

export {fetchMenu};

const fetchItemsDetails = (itemIds, selector = "Item.ItemSpecifics,Item.Site,Item.SKU,Item.Title,Item.ItemID,Item.PrimaryCategory", callbackItem) => {
		
	ajaxPerso( {
		"api": "ebay",
		"trigger": "get_item_detail",
		"shop": props.store,
		"selector": selector,
		"idList": itemIds.join(',')
	}, callbackItem);
}

export {fetchItemsDetails};

const getCollectionItemCount = (args, loadingFnc, callback) => {
		
	if(!args)	throw("getCollectionItemCount Please indicate a store ID");
	if(loadingFnc)	loadingFnc()
	
	ajaxPerso(Object.assign({
		"api": "shopify",
		"trigger": "getCollectionItemCount",
	}, args), callback);
}

export {getCollectionItemCount};

const fetchAutoCreatorMapping = (args, loadingFnc, callback) => {
		
	if(!args)	throw("fetchAutoCreatorMapping Please indicate a store ID");
	if(loadingFnc)	loadingFnc()
	
	ajaxPerso(Object.assign({
		"api": "autoCreator",
		"trigger": "get_autoCreate_mapping_rules",
	}, args), callback);
}
export {fetchAutoCreatorMapping};

const fetchAutoCreateProduct = (args, loadingFnc, callback) => {
//const fetchAutoCreateProduct = (args, loadingFnc, callback) => {
		
	if(!args)	throw("fetchAutoCreateProduct Please indicate a parameter");
	if(loadingFnc)	loadingFnc()
	
	ajaxPerso(Object.assign({
		"api": "autoCreator",
		"trigger": "get_autoCreate_product",
	}, args), callback);
}
export {fetchAutoCreateProduct};

const fetchIsAutoCreated = (args, loadingFnc, callback) => {
	if(!args)	throw("isAutoCreated Please indicate a parameter");
	if(loadingFnc)	loadingFnc()
	
	ajaxPerso(Object.assign({
		"api": "autoCreator",
		"trigger": "is_auto_created",
	}, args), callback);
}
export {fetchIsAutoCreated};


const fetchAutoCreatorFetchFeedProperties = (args, loadingFnc, callback) => {
		
	if(!args)	throw("fetchAutoCreatorFetchFeedProperties Please indicate a store ID");
	if(loadingFnc)	loadingFnc()
	
	ajaxPerso(Object.assign({
		"api": "autoCreator",
		"trigger": "get_auto_create_feed_properties_list",
	}, args), callback);
}
export {fetchAutoCreatorFetchFeedProperties};

const loadMinSellingPrices = (listData, callback) => {

	ajaxPerso( {
		"api": "eio",
		"trigger": "get_min_selling_price",
		"data": JSON.stringify(listData)
	}, callback);
}
export {loadMinSellingPrices};

const fetchPriceHistory = (id_product, callback) => {

	ajaxPerso( {
		"api": "eio",
		"trigger": "get_price_history",
		"id_product": id_product,
	}, callback);
}
export {fetchPriceHistory};

const fetchShippingRate = (args, callback) => {

	ajaxPerso( Object.assign({
		"api": "shipstation",
		"trigger": "getRatesEastWest"
	}, args), callback);
}

export {fetchShippingRate};

const fetchAvgPerSku = (args, callback) => {

	ajaxPerso( Object.assign({
		"api": "eio",
		"trigger": "getDataAvg"
	}, args), callback);
}

export {fetchAvgPerSku};

const fetchApiData = (api, trigger, args, loadingFnc, callback) => {
		
	if(!args)	throw("get_autoCreator_menu Please indicate a store ID");
	if(loadingFnc)	loadingFnc()
	
	ajaxPerso(Object.assign({
		"api": api,
		"trigger": trigger,
	}, args), callback);
}

export {fetchApiData};

const fetchMapping = (keys, callback) => {

	ajaxPerso( {
		"api": "eio",
		"trigger": "get_mapping_data",
		// "idMapping": "get_mapping_data",
		"keys": Array.isArray(keys)? keys.join(', ') : keys,
	}, callback)
}

export {fetchMapping};

const fetchStores = (callback, idBrand) => {
	let objVal = {}
	if(idBrand)
		objVal.id = idBrand

	ajaxPerso( Object.assign({
		"api": "eio",
		"trigger": "getStores",
		"inactivate": false
	}, objVal), callback);
}

export {fetchStores};

const fetchFormula = (callback, filter) => {
	
	let objVal = {}
	if(typeof filter == "object")
		objVal = Object.assign(objVal, filter)

	ajaxPerso( Object.assign({
		"api": "shipstation",
		"trigger": "getFormula",
	}, objVal), callback);
}

export {fetchFormula};

const addLog = (args, callback) => {
	
	let objVal = {}
	if(typeof args == "object")
		objVal = Object.assign(objVal, args)

	ajaxPerso( Object.assign({
		"api": "eio",
		"trigger": "add_log",
	}, objVal), callback);
}

export {addLog};

let fetchShopifyItems = (searchObj, callbackPresence, info) => {

	ajaxPerso( Object.assign({
		"api": "shopify",
		"trigger": "getItems"
	}, searchObj), callbackPresence);
}

export {fetchShopifyItems};

let fetchItems = (api, searchObj, callbackPresence, info) => {

	ajaxPerso( Object.assign({
		"api": api,
		"trigger": "getItems"
	}, searchObj), callbackPresence);
}

export {fetchItems};

const fetchItemGlobalInfo = (obj, callbackGlobalItem, callbackMargin) => {
	ajaxPerso( Object.assign({
		"api": "eio",
		"trigger": "get_global_product_info",
	}, obj), callbackGlobalItem);
}

export {fetchItemGlobalInfo};

let fetchEioCatalogItems = (searchObj, callbackPresence, info) => {

	ajaxPerso( Object.assign({
		"api": "eio",
		"trigger": "getItemsCatalog"
	}, searchObj), callbackPresence);
}

export {fetchEioCatalogItems};

let getItemsCatalog = (searchObj, callbackPresence, info) => {

	ajaxPerso( Object.assign({
		"api": "eio",
		"trigger": "getItemsCatalog_v2"
	}, searchObj), callbackPresence);
}

export {getItemsCatalog};

let findWherePosted = (args, callbackPresence) => {

	ajaxPerso( Object.assign({
		"api": "eio",
		"trigger": "presence_checker",
	}, args), callbackPresence);
}

export {findWherePosted};

const b64toBlob  = (b64Data, contentType='', sliceSize=512) => {
	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, {type: contentType});
	return blob;
}

export {b64toBlob};

const fetchBrands = (storeId, filters, loadingFnc, callback) => {
		
	if(!filters)	filters = {}
	let mainArgs = {};
	if(storeId){
		mainArgs.shop = storeId
	}
	if(loadingFnc)	loadingFnc()
	
	ajaxPerso(Object.assign({
		"api": "eio",
		"trigger": "getAllBrands",
		"filters": JSON.stringify(filters)
	}, mainArgs), callback);
}

export {fetchBrands};

const fetchPODetailsFromPoId = (filters, loadingFnc, callback) => {
		
	if(!filters)	filters = {}
	if(loadingFnc)	loadingFnc()
	
	ajaxPerso(Object.assign({
		"api": "finale",
		"trigger": "fetch_PO_details",
	}, filters), callback);
}

export {fetchPODetailsFromPoId};

const fetchPOPerSku = (filters, callback) => {
		
	if(!filters)	filters = {}
	
	ajaxPerso(Object.assign({
		"api": "finale",
		"trigger": "fetch_PO_per_sku",
	}, filters), callback);
}

export {fetchPOPerSku};

const fetchTags = (idShop, loadingFnc, callback) => {
		
	if(loadingFnc)	loadingFnc()
	
	ajaxPerso( {
		"api": "shopify",
		"trigger": "get_tags",
		"shop": idShop
	}, callback);
}

export {fetchTags};

const fetchPackages = (loadingFnc, callback) => {
		
	if(loadingFnc)	loadingFnc()
	
	ajaxPerso({
		"api": "shipstation",
		"trigger": "getPackages"
	}, callback);
}

export {fetchPackages};

const historyInsert = (shop, obj, callback) => {
	ajaxPerso({
		"api": "eio",
		"trigger": "addHistoryInsert",
		"shop": shop,
		"data": JSON.stringify(obj)
	}, callback);
}

export {historyInsert};

const fetchDataSet = (obj, callback) => {
	ajaxPerso( Object.assign({
		"api": "eio",
		"trigger": "loadDescriptionToolsData",
	}, obj), callback)
}

export {fetchDataSet};

const menuOrganizedChilds = (r, idProp, nameProp) => {
	/**
	 * Create the menu Tree cable & wire > displayPort
	 */
	let tabLine = [],
		listFam = []

	if(!idProp)
		idProp = "id_category"
	if(!nameProp)
		nameProp = "name"

	for (let v = 0; v < r.length; v++) {

		let targetItem = r[v]
		let parents = [targetItem]
		let idDoneList = [targetItem[idProp]]

		// if(targetItem.has_product_in){
		if(!targetItem.id_parent){

			targetItem.childs = []
			listFam.push(targetItem)

			recursiveChildGrouping(idDoneList, targetItem, r, null, null, idProp, parents, listFam[listFam.length-1])

			// console.info("parentsparentsparents", parents);

			if(!("has_product_in" in parents[parents.length-1]) || parents[parents.length-1].has_product_in){
				
				tabLine.push({
					last: parents[parents.length-1],
					// first: parents[parents.length-1],
					familly: parents,
					inline: parents.map(obj => {
						return obj[nameProp]
					}).join(" > ")
				})
			}
		}
	}

	console.info("listFamlistFam", listFam);

	// Add the product qty per level
	listFam.forEach(o => {
		
		/* let parents = []
		parents.push(o) */

		o.countChilds = o.product_count_to_setup;

		// recursiveChildsCounting(o, parents)
		
		o.childs.forEach(o2 => {

			o.countChilds += o2.product_count_to_setup;
			o2.countChilds = o2.product_count_to_setup;
		
			o2.childs.forEach(o3 => {

				o.countChilds += o3.product_count_to_setup;
				o2.countChilds += o3.product_count_to_setup;
				o3.countChilds = o3.product_count_to_setup;

				o3.childs.forEach(o4 => {

					o.countChilds += o4.product_count_to_setup;
					o2.countChilds += o4.product_count_to_setup;
					o3.countChilds += o4.product_count_to_setup;
					o4.countChilds = o4.product_count_to_setup;
	
				})
			})
		})
	})

	console.info("listFam", listFam);

	/* listFam = listFam.filter(o => {
		if(o.countChilds > 0)	return o;
	}) */

	console.info("tabLinetabLine", listFam);
	return listFam;
};

export {menuOrganizedChilds};

const menuOrganized = (r, idProp, nameProp) => {
	/**
	 * Create the menu Tree cable & wire > displayPort
	 */
	let tabLine = []

	if(!idProp)
		idProp = "id_category"
	if(!nameProp)
		nameProp = "name"

	for (let v = 0; v < r.length; v++) {
		let targetItem = r[v]

		let parents = [targetItem]

		for (let i = targetItem.level-1; i >= 0; i--) {
			
			for (let u = 0; u < r.length; u++) {
				let obj = r[u]
				
				if(obj.level < parents[parents.length-1].level && obj[idProp] === parents[parents.length-1].id_parent){
					parents.push(obj)
					break;
				}
			}
		}

		if(!("has_product_in" in parents[0]) || parents[0].has_product_in){
			
			tabLine.push({
				last: parents[0],
				inline: parents.map(obj => {
					return obj[nameProp]
				}).reverse().join(" > ")
			})
		}
	}

	let moreThan1Lvl = tabLine.some(o => {
		if(o.last.level > 1)	return true
	})

	if(!moreThan1Lvl){
		return tabLine
	}else{
		return tabLine.filter(o => {
			if(o.last.level != 1)	return o
		})
	}
};

export {menuOrganized};

const removeLevel = (list, lvlToRemove) => {

	let moreThan1Lvl = list.some(o => {
		if(o.level > 1)	return true
	})

	if(!moreThan1Lvl){
		return list
	}else{
		return list.filter(o => {
			if(o.last.level != lvlToRemove)	return o
		})
	}
};

export {removeLevel};

const sendEmail = function(email, msg, callback){

	if(!email || !msg)
		throw("Please indicate a email adress and a message to send.");

	if(!callback)
		callback = () => {}

	ajaxPerso({
		"trigger": "sendMail",
		"api": "eio",
		"email": email,
		"message": msg,
	}, callback);
}

export {sendEmail};

const capitalizeFLetter = (input) => {
	return input.substr(0,1).toUpperCase() + input.substr(1)
}
export {capitalizeFLetter};

const loadAccount = (args, callback) => {

	if(!args)	args = {};

	ajaxPerso({
		"trigger": "getAccount",
		"api": "account"
	}, callback);
}

export {loadAccount};

const shopifyOptions = (args, callback) => {

	if(!args)	args = {};

	ajaxPerso(Object.assign({
		"trigger": "fetchOptions",
		"api": "shopify"
	}, args), callback);
}

export {shopifyOptions};

const getSupplier = (args, callback) => {

	if(!args)	args = {};

	ajaxPerso(Object.assign({
		"trigger": "getSupplier",
		"api": "finale"
	}, args), callback);
}

export {getSupplier};

const getSupplierInfo = (args, callback) => {

	if(!args)	args = {};

	ajaxPerso(Object.assign({
		"trigger": "get_supplier_info",
		"api": "finale"
	}, args), callback);
}

export {getSupplierInfo};

const confirmation_modal = (props, successCallback, e, addedBody) => {
	if(e)	e.preventDefault();
	if(e)	e.stopPropagation();

	props.modal({
		show: true,
		title: "Confirmation",
		html: () => {
			return <div className="text-center font-weight-bold">
				Are you sure? {addedBody? addedBody : ""}
			</div>
		}
		,ok: {
			title: "I'm sure",
			fnc: (popup, close) => {
				
				if(successCallback)	successCallback(close)

			}
		},
		return: {
			title: "Close"
		}
	})
}

export {confirmation_modal};

const hasOnlyDefaultVariant = (options) => {
	/***
	 * Shopify fnc to give the same result we already got from graphQl.
	 */

	if(options.length !== 1)
		return false

	if(options[0].values.length !== 1)
		return false

	if(options[0].values[0] != "Default Title")
		return false

	return true
}

export {hasOnlyDefaultVariant};

const recursiveChildGrouping = (idDoneList, current, res, $idCatWanted, $level, idProp, parents, listFam) => {

	res.forEach(obj => {

		if(idDoneList.indexOf(obj[idProp]) === -1){

			// console.info(`${obj[idProp]} === ${parents[parents.length-1].id_parent} && ${obj.level} > ${parents[parents.length-1].level}`);
			if(obj.id_parent === listFam[idProp] && obj.level > listFam.level){
				
				obj.childs = []
				listFam.childs.push(obj)

				// parents.push(obj);
				idDoneList.push(obj[idProp])
				recursiveChildGrouping(idDoneList, obj, res, $idCatWanted, $level, idProp, parents, listFam.childs[listFam.childs.length-1]);

			}
		}
	})
}
export {recursiveChildGrouping};

/* const recursiveChildsCounting = (o, parents) => {

	if(o.product_count_to_setup > 0){
		console.error("CHECK", o);
	}

	o.countChilds = o.product_count_to_setup;

	for (let i = parents.length-1; i >=0; i--) {
		parents[i] += o.product_count_to_setup
		// console.error("Add qty to", o);
	}

	console.error("TESTSTS", o);
	parents.push(o)

	o.childs.forEach(childO => {

		recursiveChildsCounting(childO, parents);
	})
}
export {recursiveChildsCounting}; */

const diff = (listToCleanUp, listToCompareTo) => {

	// Remove from listToCleanUp the stores already existing in listToCompareTo
	return listToCompareTo?  listToCleanUp.filter(x => !listToCompareTo.includes(x)) : []
}
export {diff};

const downLoadFile = (base64, fileName, type) => {
	const anchorElement = document.createElement('a');

	document.body.appendChild(anchorElement);
	anchorElement.style.display = 'none';

	/*
	let fileType = 'application/octet-stream'

	if(type)
		fileType = type;

	const blob = new Blob([base64], {
		type: fileType,
	});

	const url = window.URL.createObjectURL(blob); */

	anchorElement.href = "data:application/octet-stream;base64," + base64;
	anchorElement.download = fileName;
	anchorElement.click();
	document.body.removeChild(anchorElement);

	// window.URL.revokeObjectURL(url);
}
export {downLoadFile};


const imgLoaderWithControl = (posImgOngoing, listImg) => {

	var img = new Image();

	img.src = listImg[posImgOngoing];

	img.onerror = function() {
		// Handle the error here
		console.log('Error loading background image');
		if(listImg.length > posImgOngoing+1)
			imgLoaderWithControl(posImgOngoing+1, listImg);
	};
}
export {imgLoaderWithControl};


const setIntervalV2 = (fnc, time, limit, pass) => {

	if(!limit)	limit = 500
	if(!pass)	pass = 0

	console.info("passpass", pass);
	console.info("limitlimit", limit);

	let timeInt = setTimeout(() => {

		if(pass > limit){
			clearInterval(timeInt);
		}else{
			fnc(timeInt);
			setIntervalV2(fnc, time, ++pass);
		}

	}, time)
}
export {setIntervalV2};

const fetchValueInventory = (args, callback) => {
			
	ajaxPerso( Object.assign({
		"trigger": "get_daily_inventory_value",
		"api": "finale",
	}, args), callback);
}
export {fetchValueInventory};

const shopifyRefreshData = (shop, skuList, idList, callback) => {

	let args = {},
		shops = []
	if(skuList)
		args.skuList = JSON.stringify(skuList)
	if(idList)
		args.idList = JSON.stringify(idList)
	if(!shop)
		shops = [refConst.id_store_eio, refConst.id_store_hausfTools]
	if(!Array.isArray(shop))
		shops = [shop]
	if(!callback)
		callback = (r) => {
			console.info('Refresh_done', r);
		}
			
	ajaxPerso( Object.assign({
		"shop": shops.join(','),
		"trigger": "refresh_catalog",
		"api": "shopify",
	}, args), callback);
}
export {shopifyRefreshData};

const adjustColor = (hexColor, redOffset = 0, greenOffset = 0, blueOffset = 0) => {
    // Remove the hash symbol if present
	if(!hexColor)	return '#000000';
    hexColor = hexColor.replace('#', '');

    // Convert hex to RGB
    let r = parseInt(hexColor.substring(0, 2), 16);
    let g = parseInt(hexColor.substring(2, 4), 16);
    let b = parseInt(hexColor.substring(4, 6), 16);

    // Adjust colors
    r = Math.max(0, Math.min(255, r + redOffset));
    g = Math.max(0, Math.min(255, g + greenOffset));
    b = Math.max(0, Math.min(255, b + blueOffset));

    // Convert back to hex and ensure 2 digits
    let newColor = '#' + 
                   ('0' + r.toString(16)).slice(-2).toUpperCase() + 
                   ('0' + g.toString(16)).slice(-2).toUpperCase() + 
                   ('0' + b.toString(16)).slice(-2).toUpperCase();

    return newColor;
}
export {adjustColor};